import { Button } from '@pijma/crypto'

import { useToken } from '@hooks'
import styled from 'styled-components'

import { DarkBgClass } from '../../../../../generated/quicktype/Tokens'
import { appClose } from '../../../../helpers/route-utils'
import { H4 } from '../../../common/header/Headings'
import { IconNegative } from '../../../common/icons/IconNegative'
import { FlexBoxCenteredLayout } from '../../../common/layout/flex-box'
import { CaptionText500 } from '../../../common/text/BodyText'

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`

const TitleWrapper = styled.div`
  margin-top: 16px;
  white-space: pre-wrap;
`

const TipWrapper = styled.div<DarkBgClass>`
  margin-top: 8px;
  margin-left: 42px;
  margin-right: 42px;
  white-space: pre-wrap;
`

const ButtonWrapper = styled.div`
  margin: 32px 0 16px 0;
`

export const ShopErrorPage = () => {
  const tokens = useToken()
  const closeWindow = appClose

  return (
    <FlexBoxCenteredLayout>
      <Container>
        <IconNegative />
        <TitleWrapper>
          <H4>
            Не удалось
            <br />
            оплатить покупку
          </H4>
        </TitleWrapper>

        <TipWrapper {...tokens.color.font.light_bg}>
          <CaptionText500>
            Крипта уже вернулась на&nbsp;ваш кошелёк. Зайдите через
            пару&nbsp;минут
          </CaptionText500>
        </TipWrapper>

        <ButtonWrapper>
          <Button
            width={'100%'}
            size={'m'}
            type={'primary'}
            onPress={() => closeWindow()}
          >
            Вернуться в QWIK
          </Button>
        </ButtonWrapper>
      </Container>
    </FlexBoxCenteredLayout>
  )
}
