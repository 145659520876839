import { httpClient, IHttpClient } from '@api'
import { EPaymentMethod, TApplicationResponse } from '@api/types'

import { EApplicationType } from '../../stores/types/EApplicationType'
import { EShop } from '../../stores/types/EShop'
import { TApplicationStatusResponse } from '../types/TApplicationStatusResponse'
import { TPersonalData } from '../types/TPersonalData'

interface ICommonApplicationClient {
  application(applicationId: string): Promise<TApplicationResponse>
  applicationStatus(applicationId: string): Promise<TApplicationStatusResponse>
  createApplication(
    type: EApplicationType,
    shop: EShop | null,
  ): Promise<TApplicationResponse>
  personalData(
    userId: string,
    paymentMethod: EPaymentMethod,
    applicationType: EApplicationType,
    shop: EShop | null,
  ): Promise<TPersonalData>
}

const commonApplicationClientFactory = (
  client: IHttpClient,
): ICommonApplicationClient => {
  return {
    application(applicationId: string): Promise<TApplicationResponse> {
      return client.get(`/client/v1/applications/${applicationId}`)
    },
    applicationStatus(
      applicationId: string,
    ): Promise<TApplicationStatusResponse> {
      return client.get(`/client/v1/applications/${applicationId}/status`)
    },
    createApplication(
      type: EApplicationType,
      shop: EShop | null,
    ): Promise<TApplicationResponse> {
      const shopPart = shop ? `?shopCode=${shop}` : ''
      return client.put(`/client/v1/applications/${type}${shopPart}`)
    },
    personalData(
      userId: string,
      paymentMethod: EPaymentMethod,
      applicationType: EApplicationType,
      shop: EShop | null,
    ): Promise<TPersonalData> {
      const shopPart = shop ? `&shopCode=${shop}` : ''
      return client.get(
        `/client/v1/applications/personal-data` +
          `?userId=${userId}` +
          `&paymentMethod=${paymentMethod}` +
          `&applicationType=${applicationType}` +
          `${shopPart}`,
      )
    },
  }
}

export const commonApplicationClient: ICommonApplicationClient =
  commonApplicationClientFactory(httpClient)
