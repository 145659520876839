import { useEffect, useReducer } from 'react'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { fromResponse } from '@api/types'
import { RootState } from '@stores'
import { TPayFormState } from '@stores/types/*'

import { commonApplicationClient } from '../../../api/clients/common-application-client'
import { payFormService } from '../../../service/payform'
import { EApplicationType } from '../../../stores/types/EApplicationType'
import { EShop, stringToEShop } from '../../../stores/types/EShop'

interface StateProps {
  payForm: TPayFormState
}

interface PutApplicationRState {
  requested: boolean
  shop: EShop
}

const putApplicationReducer = (state: PutApplicationRState) => {
  if (state.requested) {
    return state
  }

  commonApplicationClient
    .createApplication(EApplicationType.PAYMENT, state.shop)
    .then((res) => {
      payFormService.initPayForm(fromResponse(res))
    })
  return { ...state, requested: true }
}

const ApplicationShopCreatorPage = (props: StateProps) => {
  const redirectTo = useNavigate()
  const params = useParams()

  const [, putApplicationDispatch] = useReducer(putApplicationReducer, {
    requested: false,
    shop: stringToEShop(params.shopCode || ''),
  })

  useEffect(() => {
    putApplicationDispatch()
  }, [])

  useEffect(() => {
    if (!props.payForm.application?.applicationUuid) {
      return
    }
    redirectTo(
      `/application/${props.payForm.application?.applicationUuid}/payment`,
    )
  }, [props.payForm.application?.applicationUuid, redirectTo])
  return null
}

const mapStateToProps = (state: RootState): StateProps => ({
  payForm: state.payForm,
})

export default connect(mapStateToProps)(ApplicationShopCreatorPage)
