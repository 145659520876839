import { FC } from 'react'

import { useToken } from '@hooks'

import { TextProps } from './TextProps'
import { TextSpan } from './TextSpan'

export const BodyText600: FC<TextProps> = (props: TextProps) => (
  <TextSpan {...useToken().text.body['600'].default.value}>
    {props.children}
  </TextSpan>
)

export const CaptionText500: FC<TextProps> = (props: TextProps) => (
  <TextSpan
    {...useToken().text.caption['500'].value}
    style={{ color: props.color }}
  >
    {props.children}
  </TextSpan>
)

export const CaptionText600: FC<TextProps> = (props: TextProps) => (
  <TextSpan {...useToken().text.caption['600'].value}>
    {props.children}
  </TextSpan>
)

export const BodyText500: FC<TextProps> = (props: TextProps) => (
  <TextSpan
    {...useToken().text.body['500'].default.value}
    style={{ color: props.color }}
  >
    {props.children}
  </TextSpan>
)
