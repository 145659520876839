import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import { EPaymentSource } from '@stores/types/EPaymentSource'
import {
  AuthPage,
  CancelPage,
  ErrorPage,
  PurchaseOffersNotFoundPage,
  PurchasePendingPage,
  PurchaseSourcePage,
  SuccessPage,
} from 'main/ts/view/pages'

import { EShop } from '../stores/types/EShop'
import { ClosePage } from '../view/pages/common/close-page/ClosePage'
import { InterruptPaymentPage } from '../view/pages/common/error-page/InterruptPaymentPage'
import { NotFoundPage } from '../view/pages/common/error-page/NotFoundPage'
import PaymentPage from '../view/pages/common/payment-page/PaymentPage'
import ApplicationPurchaseCreatorPage from '../view/pages/purchase/ApplicationPurchaseCreatorPage'
import { CancelConfirmationPage } from '../view/pages/purchase/cancel-confirmation-page/CancelConfirmationPage'
import FindPurchaseOffersPage from '../view/pages/purchase/find-offers-page/FindPurchaseOffersPage'
import P2PPurchasePage from '../view/pages/purchase/p2p-purchase-page/P2PPurchasePage'
import { P2PPurchaseWrapperPage } from '../view/pages/purchase/P2PPurchaseWrapperPage'
import ProviderSelectPage from '../view/pages/purchase/provider-select-page/PurchaseProviderSelectPage'
import PurchaseWrapperPage from '../view/pages/purchase/PurchaseWrapperPage'
import PurchaseSourceCardPage from '../view/pages/purchase/source-page/source-card-page/PurchaseSourceCardPage'
import PurchaseSourceSbpPage from '../view/pages/purchase/source-page/source-sbp-page/PurchaseSourceSbpPage'
import PurchaseSourceSelectPage from '../view/pages/purchase/source-select-page/PurchaseSourceSelectPage'
import SumPage from '../view/pages/purchase/sum-page/PurchaseSumPage'
import ApplicationSellCreatorPage from '../view/pages/sell/ApplicationSellCreatorPage'
import FindSellOffersPage from '../view/pages/sell/find-offers-page/FindSellOffersPage'
import { SellInsufficientFundsPage } from '../view/pages/sell/insufficient-funds-page/SellInsufficientFundsPage'
import { SellOffersNotFoundPage } from '../view/pages/sell/offers-not-found-page/SellOffersNotFoundPage'
import P2PSellPage from '../view/pages/sell/p2p-sell-page/P2PSellPage'
import { P2PSellWrapperPage } from '../view/pages/sell/P2PSellWrapperPage'
import { SellSourcePage } from '../view/pages/sell/sell-source-page'
import SellSourceSbpPage from '../view/pages/sell/sell-source-page/sell-source-sbp-page/SellSourceSbpPage'
import SellWrapperPage from '../view/pages/sell/SellWrapperPage'
import SellSourceSelectPage from '../view/pages/sell/source-select-page/SellSourceSelectPage'
import SellSumPage from '../view/pages/sell/sum-page/SellSumPage'
import ApplicationShopCreatorPage from '../view/pages/shop/ApplicationShopCreatorPage'
import { ShopErrorPage } from '../view/pages/shop/error-page/ShopErrorPage'
import ShopInsufficientFundsPage from '../view/pages/shop/error-page/ShopInsufficientFundsPage'
import { ShopPendingPage } from '../view/pages/shop/ShopPendingPage'
import ShopWrapperPage from '../view/pages/shop/ShopWrapperPage'
import { HelpLoginPage } from '../view/pages/shop/steam-page/HelpLoginPage'
import { HelpSngListPage } from '../view/pages/shop/steam-page/HelpSngListPage'
import SteamPage from '../view/pages/shop/steam-page/SteamPage'
import ShopSuccessPage from '../view/pages/shop/success-page/ShopSuccessPage'

const router = createBrowserRouter([
  {
    path: '/purchase',
    element: <ApplicationPurchaseCreatorPage />,
  },
  {
    path: '/sell',
    element: <ApplicationSellCreatorPage />,
  },
  {
    path: '/payment/:shopCode',
    element: <ApplicationShopCreatorPage />,
  },
  {
    path: '/application/:applicationId/payment',
    element: <PaymentPage />,
  },
  {
    path: '/application/:applicationId/purchase',
    element: <PurchaseWrapperPage />,
    children: [
      {
        path: 'source-select',
        element: <PurchaseSourceSelectPage />,
      },
      {
        path: 'sum',
        element: <SumPage />,
      },
      {
        path: `provider-select`,
        element: <ProviderSelectPage />,
      },
      {
        path: 'source',
        element: <PurchaseSourcePage />,
        children: [
          {
            path: EPaymentSource.CARD,
            element: <PurchaseSourceCardPage />,
          },
          {
            path: EPaymentSource.SBP,
            element: <PurchaseSourceSbpPage />,
          },
        ],
      },
    ],
  },
  {
    path: '/application/:applicationId/payment/:paymentId',
    element: <P2PPurchaseWrapperPage />,
    children: [
      {
        path: 'p2p-bill',
        element: <P2PPurchasePage />,
      },
      {
        path: 'success',
        element: (
          <SuccessPage
            title="Оплачено"
            message={'Монеты зачислятся на баланс\nкошелька в течение 10 минут'}
          />
        ),
      },
      {
        path: 'pending',
        element: <PurchasePendingPage />,
      },
      {
        path: 'cancel',
        element: <CancelPage />,
      },
      {
        path: 'confirm-cancel',
        element: <CancelConfirmationPage />,
      },
    ],
  },
  {
    path: '/application/:applicationId/sell/:sellId',
    element: <P2PSellWrapperPage />,
    children: [
      {
        path: 'p2p-sell',
        element: <P2PSellPage />,
      },
      {
        path: 'success',
        element: <SuccessPage title="Монеты проданы" />,
      },
    ],
  },
  {
    path: '/application/:applicationId/sell',
    element: <SellWrapperPage />,
    children: [
      {
        path: 'source-select',
        element: <SellSourceSelectPage />,
      },
      {
        path: 'source',
        element: <SellSourcePage />,
        children: [
          {
            path: EPaymentSource.SBP,
            element: <SellSourceSbpPage />,
          },
        ],
      },
      {
        path: 'sum',
        element: <SellSumPage />,
      },
    ],
  },
  {
    path: '/application/:applicationId/shop',
    element: <ShopWrapperPage />,
    children: [
      {
        path: EShop.STEAM,
        element: <SteamPage />,
      },
      {
        path: `${EShop.STEAM}/help_login`,
        element: <HelpLoginPage />,
      },
      {
        path: `${EShop.STEAM}/help_sng_list`,
        element: <HelpSngListPage />,
      },
      {
        path: 'failure',
        element: <ShopErrorPage />,
      },
      {
        path: 'insufficient-funds',
        element: <ShopInsufficientFundsPage />,
      },
      {
        path: 'success',
        element: <ShopSuccessPage />,
      },
      {
        path: 'pending',
        element: <ShopPendingPage />,
      },
    ],
  },
  {
    path: 'application/close',
    element: <ClosePage />,
  },
  {
    path: 'application/:applicationId/purchase/offers-not-found',
    element: <PurchaseOffersNotFoundPage />,
  },
  {
    path: 'application/:applicationId/purchase/find-offers',
    element: <FindPurchaseOffersPage />,
  },
  {
    path: 'application/:applicationId/sell/find-offers',
    element: <FindSellOffersPage />,
  },
  {
    path: 'application/:applicationId/sell/offers-not-found',
    element: <SellOffersNotFoundPage />,
  },
  {
    path: 'application/:applicationId/sell/insufficient-funds',
    element: <SellInsufficientFundsPage />,
  },
  {
    path: '/auth',
    element: <AuthPage />,
  },
  {
    path: 'error',
    element: <ErrorPage tip={'Обновите страницу или зайдите позже'} />,
  },
  {
    path: 'timeout',
    element: <InterruptPaymentPage />,
  },
  {
    path: 'failure',
    element: (
      <ErrorPage
        title={'Платеж не\u00A0прошёл'}
        tip="Попробуйте ещё раз"
        data-testid="result-page.failure"
      />
    ),
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
])

export const Router = () => {
  return <RouterProvider router={router} />
}
