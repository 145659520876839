import { FC } from 'react'

import { Button } from '@pijma/crypto'
import { IconLike } from '@pijma/icon-gallery/crypto'

import styled from 'styled-components'

import { appClose } from '../../../helpers/route-utils'
import { H4 } from '../header/Headings'
import { FlexBoxCenteredLayout } from '../layout/flex-box'
import { CaptionText500 } from '../text/BodyText'

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-top: -80px;
`

const TitleWrapper = styled.div`
  margin-top: 12px;
  margin-bottom: 8px;
  white-space: pre-wrap;
`

const DescriptionWrapper = styled.div`
  white-space: pre-wrap;
`

const ButtonContainer = styled.div`
  width: 100%;
  padding: 0 20px 20px 20px;
  position: absolute;
  bottom: 0;
`

interface ISuccessPageProps {
  title?: string
  message?: string
}

export const SuccessPage: FC<ISuccessPageProps> = ({ title, message }) => {
  const closeWindow = appClose

  return (
    <FlexBoxCenteredLayout>
      <Container data-testid="result-page.success">
        <IconLike width="61px" height="61px" />
        <TitleWrapper>
          <H4>{title}</H4>
        </TitleWrapper>

        {message ? (
          <DescriptionWrapper>
            <CaptionText500>{message}</CaptionText500>
          </DescriptionWrapper>
        ) : null}

        <ButtonContainer data-testid="result-page.close">
          <Button
            width={'100%'}
            size={'m'}
            type={'primary'}
            onPress={() => closeWindow()}
          >
            Закрыть
          </Button>
        </ButtonContainer>
      </Container>
    </FlexBoxCenteredLayout>
  )
}
