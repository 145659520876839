import { createModel } from '@rematch/core'
import { RootModel } from '@stores'

import { TNspkBanksState } from '../types/TNspkBanksState'

export const nspkBanks = createModel<RootModel>()({
  state: {} as TNspkBanksState,
  reducers: {
    set(state, payload: TNspkBanksState) {
      return { ...state, ...payload }
    },
  },
  effects: (dispatch) => ({
    init(payload: TNspkBanksState) {
      dispatch.nspkBanks.set(payload)
    },
  }),
})
