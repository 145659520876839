import { Spinner } from '@pijma/crypto'

import styled from 'styled-components'

import { H4 } from '../../../common/header/Headings'
import { FlexBoxCenteredLayout } from '../../../common/layout/flex-box'

const DWalletLogoWrapper = styled.div`
  margin-top: 16px;
`

const HeaderWrapper = styled.div`
  margin-top: 16px;
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  white-space: pre-wrap;
`

export const DWalletTransfer = () => (
  <FlexBoxCenteredLayout>
    <Container>
      <Spinner size={28} />
      <HeaderWrapper>
        <H4>
          Вы&nbsp;переходите
          <br />
          к&nbsp;партнёру
        </H4>
      </HeaderWrapper>
      <DWalletLogoWrapper>
        <img
          src={`${process.env.PUBLIC_URL}/img/d-wallet-logo.svg`}
          alt={`Dealer Wallet`}
        />
      </DWalletLogoWrapper>
    </Container>
  </FlexBoxCenteredLayout>
)
