import { fromResponse } from '@api/types'

import { commonApplicationClient } from '../../api/clients/common-application-client'
import { payFormService } from '../../service/payform'

export interface TApplicationState {
  applicationId: string
}

export interface ApplicationReducerAction {
  action: ApplicationReducerActions
  applicationId: string
}

export enum ApplicationReducerActions {
  GET,
}

export const applicationReducer = (
  state: TApplicationState,
  action: ApplicationReducerAction,
) => {
  if (action.action === ApplicationReducerActions.GET) {
    if (!action.applicationId) return state
    if (state.applicationId === action.applicationId) return state
    commonApplicationClient.application(action.applicationId).then((res) => {
      payFormService.initPayForm(fromResponse(res))
    })
    return { applicationId: action.applicationId }
  }
  return { applicationId: '' }
}
