import { useEffect, useReducer } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { fromResponse } from '@api/types'
import { RootState } from '@stores'
import { TPayFormState } from '@stores/types/*'

import { commonApplicationClient } from '../../../api/clients/common-application-client'
import { payFormService } from '../../../service/payform'
import { EApplicationType } from '../../../stores/types/EApplicationType'

interface StateProps {
  payForm: TPayFormState
}

interface PutApplicationRState {
  requested: boolean
}

const putApplicationReducer = (state: PutApplicationRState) => {
  if (state.requested) {
    return state
  }
  commonApplicationClient
    .createApplication(EApplicationType.SELL, null)
    .then((res) => {
      payFormService.initPayForm(fromResponse(res))
    })
  return { requested: true }
}

const ApplicationSellCreatorPage = (props: StateProps) => {
  const redirectTo = useNavigate()
  const [, putApplicationDispatch] = useReducer(putApplicationReducer, {
    requested: false,
  })

  useEffect(() => {
    putApplicationDispatch()
  }, [])

  useEffect(() => {
    if (!props.payForm.application?.applicationUuid) {
      return
    }
    redirectTo(
      `/application/${props.payForm.application?.applicationUuid}/payment`,
    )
  }, [props.payForm.application?.applicationUuid, redirectTo])
  return null
}

const mapStateToProps = (state: RootState): StateProps => ({
  payForm: state.payForm,
})

export default connect(mapStateToProps)(ApplicationSellCreatorPage)
