import { FC } from 'react'

import { ErrorPage } from './ErrorPage'

export const NotFoundPage: FC = () => (
  <ErrorPage
    title={
      'То, что вы\u00A0ищете\u00A0\u2014 потерялось или никогда не\u00A0существовало'
    }
  />
)
