import { FC } from 'react'

import { createGlobalStyle } from 'styled-components'

const GlobalStyleBody = createGlobalStyle`
  body {
    overflow-y: hidden;
    font-family: Inter, serif;
    font-smooth: never;
    -webkit-font-smoothing: antialiased;

    // Firefox
    -moz-osx-font-smoothing: grayscale;
  }
`

export const GlobalStyle: FC = () => <GlobalStyleBody />
