import { useEffect, useState } from 'react'

interface TimerComponentProps {
  expirationDate: string // Expecting an ISO date string
  displayText: (timeLeft: number) => string
  interval?: number
}

export const Timer = ({
  expirationDate,
  displayText,
  interval = 1000,
}: TimerComponentProps) => {
  const [timeLeft, setTimeLeft] = useState<number>(0)

  useEffect(() => {
    const updateTimer = () => {
      const now = Date.now()
      const endDate = new Date(expirationDate).getTime()
      const difference = endDate - now

      setTimeLeft(difference > 0 ? difference : 0)
    }

    updateTimer()

    const intervalId = setInterval(updateTimer, interval)

    return () => clearInterval(intervalId)
  }, [expirationDate, timeLeft, interval])

  return <>{displayText(timeLeft)}</>
}
