import { FlexBoxCenteredLayout } from '../../../common/layout/flex-box'
import { Outlet } from '../../../common/Outlet'

export const SellSourcePage = () => {
  return (
    <FlexBoxCenteredLayout>
      <Outlet />
    </FlexBoxCenteredLayout>
  )
}
