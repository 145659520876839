import { connect } from 'react-redux'

import { Button } from '@pijma/crypto'
import { IconLike } from '@pijma/icon-gallery/crypto'

import { useToken } from '@hooks'
import { RootState } from '@stores'
import { TPayFormState } from '@stores/types/*'
import styled from 'styled-components'

import { appClose } from '../../../../helpers/route-utils'
import { H4 } from '../../../common/header/Headings'
import { FlexBoxCenteredLayout } from '../../../common/layout/flex-box'
import { BodyText500 } from '../../../common/text/BodyText'

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-top: -80px;
`

const TitleWrapper = styled.div`
  margin-top: 12px;
  margin-bottom: 4px;
  white-space: pre-wrap;
`

const DescriptionWrapper = styled.div`
  white-space: pre-wrap;
`

const ButtonContainer = styled.div`
  width: 100%;
  padding: 0 20px 20px 20px;
  position: absolute;
  bottom: 0;
`

interface StateProps {
  payForm: TPayFormState
}

const ShopSuccessPage = (props: StateProps) => {
  const closeWindow = appClose

  return (
    <FlexBoxCenteredLayout>
      <Container data-testid="result-page.success">
        <IconLike width="61px" height="61px" />
        <TitleWrapper>
          <H4>Покупка оплачена</H4>
        </TitleWrapper>

        <DescriptionWrapper>
          <BodyText500
            color={useToken().color.font.light_bg.secondary.default.value}
          >
            {props.payForm.shopName}
          </BodyText500>
        </DescriptionWrapper>

        <ButtonContainer data-testid="result-page.close">
          <Button
            width={'100%'}
            size={'m'}
            type={'primary'}
            onPress={() => closeWindow()}
          >
            Закрыть
          </Button>
        </ButtonContainer>
      </Container>
    </FlexBoxCenteredLayout>
  )
}

const mapStateToProps = (state: RootState): StateProps => ({
  payForm: state.payForm,
})

export default connect(mapStateToProps)(ShopSuccessPage)
