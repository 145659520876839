import { codeToStatus, EApplicationStatus } from './EApplicationStatus'
import { TApplicationStatusResponse } from './TApplicationStatusResponse'

export type TApplicationStatus = {
  status: EApplicationStatus
}

export const fromResponse = (
  res: TApplicationStatusResponse,
): TApplicationStatus => {
  return {
    status: codeToStatus(res.status_code),
  } as TApplicationStatus
}
