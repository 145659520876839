import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Button, Input, Spinner } from '@pijma/crypto'
import { useNumericMask } from '@pijma/input'
import { SourceInfo } from '@pijma/input/lib/dts/hooks/utils/types'

import { Dispatch, RootState } from '@stores'
import { TPayFormState } from '@stores/types/*'
import { localeNumber } from '@utils'
import styled from 'styled-components'

import { H4 } from '../../../common/header/Headings'
import { Island } from '../../../common/island'
import { FlexBoxCenteredLayout } from '../../../common/layout/flex-box'
import NavigationBar from '../../../common/navigation/NavigationBar'
import { CaptionText500 } from '../../../common/text/BodyText'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 20px;
`

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0 20px 24px;
`

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 32px 20px 20px;
`

const RateTipContainer = styled.div`
  color: #868686;
  padding: 0 32px;
  text-align: center;
`

const SourceTitle = styled.div`
  display: flex;
  width: 100%;
  padding: 20px 24px 16px;
`

const NavigationBarWrapper = styled.div`
  padding: 0 4px;
  display: flex;
  width: 100%;
  align-items: start;
`

interface StateProps {
  payForm: TPayFormState
  prettyRate: number
}

interface DispatchProps {
  setAmount: (amount: string) => void
}

type Props = StateProps & DispatchProps

const trueAmount = (formattedAmount: string) => {
  return formattedAmount.replace(',', '.').replaceAll(' ', '')
}

// Только сумму с запятой можно вставлять в поле для дальнейшего форматирования. Точка теряется.
// Костыль, но лучше не придумал, да и вроде как пофиг
const fixableAmount = (formattedAmount: string | undefined) => {
  if (formattedAmount == undefined) return ''
  return formattedAmount
    .replace('.', ',')
    .replaceAll(' ', '')
    .replace(/(\d+),(\d\d)\d*/g, '$1,$2')
}

const convertWithRate = (amount: string, rate: number) => {
  return fixableAmount(String(+amount * rate))
}

const convertWithReverseRate = (amount: string, rate: number) => {
  return convertWithRate(amount, 1 / rate)
}

const PurchaseSumPage = (props: Props) => {
  const redirectTo = useNavigate()

  const [prettyAmountUsdt, setPrettyAmountUsdt] = useState('')
  const [prettyAmountRub, setPrettyAmountRub] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    const amount = +(props.payForm.amount as string)
    const amountOutsideLimits =
      amount &&
      (amount < +(props.payForm.minLimit as string) ||
        amount > +(props.payForm.maxLimit as string))
    const incorrectAmount = Number.isNaN(amount)

    if (amountOutsideLimits) {
      setErrorMessage(
        `Введите сумму от ${localeNumber(
          props.payForm.minLimit,
        )} до ${localeNumber(props.payForm.maxLimit)} ₽`,
      )
    } else if (incorrectAmount) {
      setErrorMessage('Это не похоже на сумму')
    } else {
      setErrorMessage('')
    }
  }, [
    props.payForm.amount,
    props.payForm.maxLimit,
    props.payForm.minLimit,
    props.payForm.application?.currency,
  ])

  const maskPropsUsdt = useNumericMask({
    value: prettyAmountUsdt,
    decimalScale: 2,
    allowedDecimalSeparators: ['%', '.', ',', 'б', 'ю'],
    decimalSeparator: ',',
    thousandsGroupStyle: 'thousand',
    thousandSeparator: '',
    staticSeparator: true,
    allowLeadingZeros: false,
    allowNegative: false,
    onValueChange: ({ formattedValue }, sourceInfo: SourceInfo) => {
      if (sourceInfo.event) {
        const converted = convertWithRate(
          trueAmount(formattedValue),
          props.prettyRate,
        )
        props.setAmount(trueAmount(converted))
        setPrettyAmountRub(converted)
      }
      setPrettyAmountUsdt(formattedValue)
    },
  })

  const maskPropsRub = useNumericMask({
    value: prettyAmountRub,
    decimalScale: 2,
    allowedDecimalSeparators: ['%', '.', ',', 'б', 'ю'],
    decimalSeparator: ',',
    thousandsGroupStyle: 'thousand',
    thousandSeparator: '',
    staticSeparator: true,
    allowLeadingZeros: false,
    allowNegative: false,
    onValueChange: ({ formattedValue }, sourceInfo: SourceInfo) => {
      if (sourceInfo.event) {
        props.setAmount(trueAmount(formattedValue))
        setPrettyAmountUsdt(
          convertWithReverseRate(trueAmount(formattedValue), props.prettyRate),
        )
      }
      setPrettyAmountRub(formattedValue)
    },
  })

  return (
    <FlexBoxCenteredLayout>
      <Container>
        <NavigationBarWrapper>
          <NavigationBar
            paymentMethodRedirectLink={'../source-select'}
            dataRedirectLink={`../source/${props.payForm.paymentSource}`}
            paymentDisabled
          />
        </NavigationBarWrapper>
        <Island size="M" bgColor="primary">
          <SourceTitle data-testid="amount-page.header">
            <H4>Сколько</H4>
          </SourceTitle>
          <InputContainer data-testid="amount-page.input.usdt">
            <Input
              {...maskPropsUsdt}
              label={`USDT`}
              value={prettyAmountUsdt}
              width={'100%'}
              description={`1 USDT = ${fixableAmount(
                props.prettyRate?.toString(),
              )} ₽`}
              invalid={errorMessage != ''}
              disabled={props.payForm.paymentBlocking}
              type={'text'}
              placeholder={'0'}
              allowClear={true}
            />
          </InputContainer>
          <InputContainer data-testid="amount-page.input.rub">
            <Input
              {...maskPropsRub}
              label={`Рубли`}
              value={prettyAmountRub}
              width={'100%'}
              errorMessage={errorMessage}
              description={`${localeNumber(
                props.payForm.minLimit,
              )} – ${localeNumber(props.payForm.maxLimit)} ₽`}
              invalid={errorMessage != ''}
              disabled={props.payForm.paymentBlocking}
              type={'text'}
              placeholder={'0'}
              allowClear={true}
            />
          </InputContainer>
          <RateTipContainer>
            <CaptionText500>
              Курс может незначительно измениться после открытия сделки
            </CaptionText500>
          </RateTipContainer>
          <ButtonContainer data-testid="amount-page.button.forward">
            <Button
              width={'100%'}
              size={'m'}
              disabled={
                props.payForm.paymentBlocking ||
                !+(props.payForm.amount as string) ||
                errorMessage != ''
              }
              onPress={() => redirectTo('../find-offers')}
            >
              {props.payForm.paymentBlocking ? (
                <Spinner size={28} color={'#fff'} />
              ) : (
                'К оплате'
              )}
            </Button>
          </ButtonContainer>
        </Island>
      </Container>
    </FlexBoxCenteredLayout>
  )
}

const mapStateToProps = (state: RootState): StateProps => ({
  payForm: state.payForm,
  prettyRate: state.payForm?.rate as number,
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    setAmount: (amount: string) =>
      dispatch.payForm.set({
        amount,
      }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseSumPage)
