import { useEffect, useReducer } from 'react'
import { useParams } from 'react-router-dom'

import { topupApplicationClient } from '@api'

import { p2pPurchaseService } from '../../../service/payform'
import { Outlet } from '../../common/Outlet'

interface PaymentReceiverState {
  fetched: boolean
}

const paymentReceiverReducer = (
  state: PaymentReceiverState,
  paymentId: string,
) => {
  if (!state.fetched) {
    topupApplicationClient
      .paymentReceiver(paymentId)
      .then((res) => p2pPurchaseService.initP2PReceiverInfo(res))
    return { fetched: true }
  }
  return state
}

export const P2PPurchaseWrapperPage = () => {
  const pathParams = useParams()
  const paymentId = pathParams.paymentId as string

  const initialPaymentReceiver = { fetched: false }

  const [, paymentReceiverDispatch] = useReducer(
    paymentReceiverReducer,
    initialPaymentReceiver,
  )

  useEffect(() => {
    paymentReceiverDispatch(paymentId)
  }, [paymentId])

  return (
    <>
      <Outlet />
    </>
  )
}
