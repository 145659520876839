import { IconCloseSmall } from '@pijma/icon-gallery/crypto'

import styled from 'styled-components'

import { H4 } from '../../header/Headings'
import { InfoSearchData } from './InfoSearchData'
import InputSearch from './InputSearch'

const ModalWrapper = styled.div<ModalWrapperProps>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fafafa;
  display: ${(props) => (props.show ? 'block' : 'none')};
`

const CloseButton = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  border-radius: 16px;
  background-color: white;
  cursor: pointer;
`

const ModalContent = styled.div`
  padding: 20px;
`

const ModalTitle = styled.span`
  margin-left: 4px;
  line-height: 28px;
  margin-bottom: 24px;
  display: inline-block;
`

interface ModalWrapperProps {
  show: boolean
}

interface ModalProps {
  isOpen: boolean
  closeModal: () => void
  data: InfoSearchData[]
  searchTitle: string
  searchPlaceholder: string
  onSearchElementSelect: (selected: InfoSearchData) => void
}

const Modal = ({
  isOpen,
  closeModal,
  searchTitle,
  searchPlaceholder,
  onSearchElementSelect,
  data,
}: ModalProps) => {
  return (
    <ModalWrapper show={isOpen}>
      <CloseButton>
        <IconCloseSmall width={32} height={32} onClick={closeModal} />
      </CloseButton>
      <ModalContent>
        <ModalTitle>
          <H4>{searchTitle}</H4>
        </ModalTitle>
        <InputSearch
          placeholder={searchPlaceholder}
          width={'100%'}
          onElementSelect={onSearchElementSelect}
          data={data}
        />
      </ModalContent>
    </ModalWrapper>
  )
}

export default Modal
