import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Button, Input, Spinner } from '@pijma/crypto'
import { IconHelp } from '@pijma/icon-gallery/crypto'
import { useNumericMask } from '@pijma/input'
import { SourceInfo } from '@pijma/input/lib/dts/hooks/utils/types'

import { useToken } from '@hooks'
import { Dispatch, RootState } from '@stores'
import { TPayFormState } from '@stores/types/TPayFormState'
import { localeNumber } from '@utils'
import { HttpStatusCode } from 'axios'
import styled from 'styled-components'

import { Color } from '../../../../../generated/quicktype/Tokens'
import { shopApplicationClient } from '../../../../api/clients/shop-application-client'
import { EShopPaymentErrorCause } from '../../../../api/types/EShopPaymentErrorCause'
import { TFailureShopPaymentResponse } from '../../../../api/types/TFailureShopPaymentResponse'
import { EShop } from '../../../../stores/types/EShop'
import { H3Weight600 } from '../../../common/header/Headings'
import { Island } from '../../../common/island'
import { FlexBoxCenteredLayout } from '../../../common/layout/flex-box'
import { BodyText600, CaptionText500 } from '../../../common/text/BodyText'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 20px;
`

const LoginContainer = styled.div`
  display: flex;
  width: 100%;
`

const LoginButtonWithHelpWrapper = styled.div`
  display: flex;
  margin: 20px;
  flex-direction: column;
`

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 32px 0 0;
`

const LoginHelpContainer = styled.div`
  margin: 8px 0 0 8px;
  cursor: pointer;
  width: fit-content;
`

const PaymentIslandWrapper = styled.div`
  padding: 8px 0 0 0;
  width: 100%;
`

const PaymentFiatWrapper = styled.div`
  padding: 20px 20px 0;
  width: 100%;
`

const PaymentCryptoWrapper = styled.div`
  padding: 24px 20px 20px;
  width: 100%;
`

const Header = styled.div<Color>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${(color) => color.bg.page.value};
  padding-left: 4px;
`

const IslandBlock = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 24px;
  font-weight: 600;
  flex-direction: column;
`

const SngBlock = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  margin: 8px 0 0 0;
  cursor: pointer;
`

interface StateProps {
  payForm: TPayFormState
  fee: number
}

interface DispatchProps {
  setAmount: (amount: string) => void
  doPaymentBlocking: () => void
  resetPaymentBlocking: () => void
}

type Props = StateProps & DispatchProps

const trueAmount = (formattedAmount: string) => {
  return formattedAmount.replace(',', '.').replaceAll(' ', '')
}

// Только сумму с запятой можно вставлять в поле для дальнейшего форматирования. Точка теряется.
// Костыль, но лучше не придумал, да и вроде как пофиг
const fixableAmount = (formattedAmount: string | undefined) => {
  if (formattedAmount == undefined) return ''
  return formattedAmount
    .replace('.', ',')
    .replaceAll(' ', '')
    .replace(/(\d+),(\d\d)\d*/g, '$1,$2')
}

const convertWithFee = (amount: string, fee: number) => {
  return fixableAmount(String(+amount * (1 + fee)))
}

const reverseConvertWithFee = (amount: string, fee: number) => {
  return fixableAmount(String(+amount / (1 + fee)))
}

const SteamPage = (props: Props) => {
  const redirectTo = useNavigate()

  const [steamLogin, setSteamLogin] = useState('')
  const [prettyAmountUsdt, setPrettyAmountUsdt] = useState('')
  const [prettyAmountFiat, setPrettyAmountFiat] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [loginErrorMessage, setLoginErrorMessage] = useState('')
  const [loginOk, setLoginOk] = useState(false)

  useEffect(() => {
    const amount = +(props.payForm.amount as string)
    const amountOutsideLimits =
      amount &&
      (amount < +(props.payForm.minLimit as string) ||
        amount > +(props.payForm.maxLimit as string))
    const incorrectAmount = Number.isNaN(amount)

    if (amountOutsideLimits) {
      setErrorMessage(
        `Сумма должна быть от ${localeNumber(
          props.payForm.minLimit,
        )} до ${localeNumber(props.payForm.maxLimit)} $`,
      )
    } else if (incorrectAmount) {
      setErrorMessage('Это не похоже на сумму')
    } else {
      setErrorMessage('')
    }
  }, [
    props.payForm.amount,
    props.payForm.maxLimit,
    props.payForm.minLimit,
    props.payForm.application?.currency,
  ])

  const maskSteamLogin = {
    onChange: (e: { target: { value: string } }) => {
      setLoginOk(false)
      setSteamLogin(() => e.target.value)
    },
  }

  const checkAccount = (account: string) => {
    if (account === '') {
      setLoginErrorMessage('')
      setLoginOk(false)
      return
    }
    const ifLoginLooksReal = /^\w{3,44}$/.test(account)
    if (!ifLoginLooksReal) {
      setLoginErrorMessage('Это не похоже на аккаунт')
      setLoginOk(false)
      return
    }
    shopApplicationClient
      .checkAccount(EShop.STEAM, account)
      .then(() => {
        setLoginErrorMessage('')
        setLoginOk(true)
      })
      .catch((error) => {
        setLoginOk(false)
        if (error.response.status == HttpStatusCode.NotFound) {
          setLoginErrorMessage('Это не СНГ-аккаунт. Попробуйте\u00A0другой')
        } else {
          setLoginErrorMessage(
            'Не смогли проверить аккаунт\nили его не\u00A0существует',
          )
        }
      })
  }

  const pay = () => {
    props.doPaymentBlocking()
    shopApplicationClient
      .payment(
        props.payForm.application?.applicationUuid || '',
        steamLogin,
        props.payForm.amount || '',
      )
      .then(() => redirectTo('../pending'))
      .catch((error) => {
        props.resetPaymentBlocking()
        const err = error.response.data as TFailureShopPaymentResponse
        // eslint-disable-next-line sonarjs/no-small-switch
        switch (err.cause) {
          case EShopPaymentErrorCause.INSUFFICIENT_FUNDS:
            redirectTo('../insufficient-funds')
            return
          default:
            redirectTo('../failure')
            return
        }
      })
  }

  const maskPropsFiat = useNumericMask({
    value: prettyAmountFiat,
    decimalScale: 2,
    allowedDecimalSeparators: ['%', '.', ',', 'б', 'ю'],
    decimalSeparator: ',',
    thousandsGroupStyle: 'thousand',
    thousandSeparator: '',
    staticSeparator: true,
    allowLeadingZeros: false,
    allowNegative: false,
    onValueChange: ({ formattedValue }, sourceInfo: SourceInfo) => {
      if (sourceInfo.event) {
        const amount = trueAmount(formattedValue)
        props.setAmount(amount)
        setPrettyAmountUsdt(convertWithFee(amount, props.fee))
      }
      setPrettyAmountFiat(formattedValue)
    },
  })

  const maskPropsUsdt = useNumericMask({
    value: prettyAmountUsdt,
    decimalScale: 2,
    allowedDecimalSeparators: ['%', '.', ',', 'б', 'ю'],
    decimalSeparator: ',',
    thousandsGroupStyle: 'thousand',
    thousandSeparator: '',
    staticSeparator: true,
    allowLeadingZeros: false,
    allowNegative: false,
    onValueChange: ({ formattedValue }, sourceInfo: SourceInfo) => {
      if (sourceInfo.event) {
        const amountFiat = reverseConvertWithFee(
          trueAmount(formattedValue),
          props.fee,
        )
        props.setAmount(trueAmount(amountFiat))
        setPrettyAmountFiat(amountFiat)
      }
      setPrettyAmountUsdt(formattedValue)
    },
  })

  const percentageFee = ((props.payForm.fee || 0) * 100).toFixed(0)
  const tokens = useToken()

  useEffect(() => {
    setSteamLogin(props.payForm.account || '')
    checkAccount(props.payForm.account || '')
  }, [props.payForm.account])

  return (
    <FlexBoxCenteredLayout>
      <Container>
        <Header {...tokens.color}>
          <IslandBlock>
            <H3Weight600>Пополнение Steam</H3Weight600>
            <SngBlock onClick={() => redirectTo('help_sng_list')}>
              <img
                src={`${process.env.PUBLIC_URL}/img/sng_logo.png`}
                width={'24px'}
                height={'24px'}
                style={{ borderRadius: '50%', marginRight: '8px' }}
              />
              <BodyText600>Страны СНГ</BodyText600>
              <IconHelp
                width={'20px'}
                height={'20px'}
                style={{ marginLeft: '4px' }}
              />
            </SngBlock>
          </IslandBlock>
        </Header>
        <Island size="M" bgColor="primary">
          <LoginButtonWithHelpWrapper>
            <LoginContainer>
              <Input
                onChange={maskSteamLogin.onChange}
                onBlur={(e) => checkAccount(e.target.value)}
                label={`Логин Steam`}
                value={steamLogin}
                width={'100%'}
                errorMessage={loginErrorMessage}
                invalid={loginErrorMessage != ''}
                disabled={props.payForm.paymentBlocking}
                type={'text'}
                allowClear={true}
              />
            </LoginContainer>
            {!loginErrorMessage && (
              <LoginHelpContainer onClick={() => redirectTo('help_login')}>
                <CaptionText500 color={tokens.color.font.link.default.value}>
                  Где найти?
                </CaptionText500>
              </LoginHelpContainer>
            )}
          </LoginButtonWithHelpWrapper>
        </Island>
        <PaymentIslandWrapper>
          <Island size="M" bgColor="primary">
            <PaymentFiatWrapper>
              <Input
                {...maskPropsFiat}
                label={`Сумма пополнения, $`}
                value={prettyAmountFiat}
                width={'100%'}
                description={
                  errorMessage ||
                  `${props.payForm.minLimit} – ${props.payForm.maxLimit} $`
                }
                invalid={errorMessage != ''}
                disabled={props.payForm.paymentBlocking}
                type={'text'}
                placeholder={'0'}
                allowClear={true}
              />
            </PaymentFiatWrapper>
            <PaymentCryptoWrapper>
              <Input
                {...maskPropsUsdt}
                label={`Оплатить в USDT`}
                value={prettyAmountUsdt}
                width={'100%'}
                description={`Комиссия ${percentageFee}%`}
                disabled={props.payForm.paymentBlocking}
                type={'text'}
                placeholder={'0'}
                allowClear={true}
              />
            </PaymentCryptoWrapper>
          </Island>
          <ButtonContainer data-testid="amount-page.button.forward">
            <Button
              width={'100%'}
              size={'m'}
              disabled={
                props.payForm.paymentBlocking ||
                !+(props.payForm.amount as string) ||
                errorMessage != '' ||
                loginErrorMessage != '' ||
                !steamLogin ||
                !loginOk
              }
              onPress={() => pay()}
            >
              {props.payForm.paymentBlocking ? (
                <Spinner size={28} color={'#fff'} />
              ) : (
                'Оплатить'
              )}
            </Button>
          </ButtonContainer>
        </PaymentIslandWrapper>
      </Container>
    </FlexBoxCenteredLayout>
  )
}

const mapStateToProps = (state: RootState): StateProps => ({
  payForm: state.payForm,
  fee: state.payForm?.fee as number,
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    setAmount: (amount: string) =>
      dispatch.payForm.set({
        amount,
      }),
    doPaymentBlocking: () =>
      dispatch.payForm.set({
        paymentBlocking: true,
      }),
    resetPaymentBlocking: () =>
      dispatch.payForm.set({
        paymentBlocking: false,
      }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SteamPage)
