import React, { FC, useEffect, useState } from 'react'

import { CommonComponentProps } from '@pijma/hero-types/lib/dts/components'
import { IconSearch } from '@pijma/icon-gallery/crypto'

import { useToken } from '@hooks'
import styled from 'styled-components'

import { H1Value } from '../../../../../generated/quicktype/Tokens'
import { TextProps } from '../../text/TextProps'
import { TextSpan } from '../../text/TextSpan'
import { InfoSearchData } from './InfoSearchData'

const InputSearchContainer = styled.div`
  position: relative;
  margin-right: 14px;
`

const InputSearchElement = styled.input<H1Value>`
  ${(props) => props.width && `width: ${props.width}`};
  height: 68px;
  padding: 22px 16px 22px 52px;
  border: 1px solid rgb(217, 217, 217);
  border-radius: 34px;
  font-family: ${(props) => props.fontFamily};
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize};
  line-height: ${(props) => props.lineHeight};
  color: #868686;
  margin-right: 34px;
`

const IconSearchWrapper = styled.div<InputSearchElementProps>`
  ${(props) => props.width && `width: ${props.width}`};
  height: 68px;
  padding: 22px 16px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: start;
  vertical-align: middle;
`

const SearchListContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 164px;
  bottom: 0;
  overflow-y: scroll;
  -ms-overflow-y: scroll;
`

const SearchListItem = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: start;
  vertical-align: middle;
  margin-bottom: 16px;
  cursor: pointer;

  span {
    display: inline-block;
    line-height: 48px;
    margin-left: 12px;
  }
`

const Caption: FC<TextProps> = (props: TextProps) => (
  <TextSpan {...useToken().text.caption['500'].value} lineHeight={'20px'}>
    {props.children}
  </TextSpan>
)

interface InputSearchElementProps {
  width?: React.CSSProperties['width']
  height?: React.CSSProperties['height']
}

interface InputSearchProps extends CommonComponentProps {
  placeholder?: string
  width?: React.CSSProperties['width']
  data: InfoSearchData[] | undefined
  onElementSelect: (selected: InfoSearchData) => void
}

const InputSearch = ({
  placeholder,
  width,
  data,
  onElementSelect,
}: InputSearchProps) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [filteredData, setFilteredData] = useState<
    InfoSearchData[] | undefined
  >(data)

  // Function to handle search input changes
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value
    setInputValue(searchValue)

    // Filter the JSON array based on search value (case-insensitive)
    const filtered =
      data &&
      data.filter((item: InfoSearchData) =>
        item.name.toLowerCase().includes(searchValue.toLowerCase()),
      )

    setFilteredData(filtered) // Update the state with filtered results
  }

  useEffect(() => {
    setFilteredData(data)
  }, [data])

  return (
    <>
      <InputSearchContainer>
        <InputSearchElement
          value={inputValue}
          onChange={handleSearch}
          width={width}
          placeholder={placeholder}
          {...useToken().text.caption['500'].value}
        />
        <IconSearchWrapper>
          <IconSearch width={24} height={24} />
        </IconSearchWrapper>
      </InputSearchContainer>
      <SearchListContainer>
        {filteredData && filteredData?.length > 0 ? (
          filteredData.map((item) => (
            <SearchListItem key={item.id} onClick={() => onElementSelect(item)}>
              <img src={item.logo} width={48} height={48} />
              <Caption>{item.name}</Caption>
            </SearchListItem>
          ))
        ) : (
          <></>
        )}
      </SearchListContainer>
    </>
  )
}

export default InputSearch
