import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Button } from '@pijma/crypto'

import { RootState } from '@stores'
import { TPayFormState } from '@stores/types/*'
import styled from 'styled-components'

import { appClose } from '../../../../helpers/route-utils'
import { H4 } from '../../../common/header/Headings'
import { IconNegative } from '../../../common/icons/IconNegative'
import { FlexBoxCenteredLayout } from '../../../common/layout/flex-box'
import { BodyText600, CaptionText500 } from '../../../common/text/BodyText'

interface StateProps {
  payForm: TPayFormState
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  white-space: pre-wrap;
`

const TitleWrapper = styled.div`
  margin-top: 16px;
  white-space: pre-wrap;
`

const TipWrapper = styled.div`
  margin-top: 8px;
  margin-left: 42px;
  margin-right: 42px;
  white-space: pre-wrap;
`

const BackContainer = styled.div`
  white-space: pre-wrap;
  margin-top: 24px;
  color: #217aac;

  span {
    cursor: pointer;
    :hover {
      color: rgb(49, 49, 49);
    }
  }
`

const ButtonWrapper = styled.div`
  margin: 32px 0 0 0;
`

const ShopInsufficientFundsPage = (props: StateProps) => {
  const redirectTo = useNavigate()

  return (
    <FlexBoxCenteredLayout>
      <Container>
        <IconNegative />
        <TitleWrapper>
          <H4>{'В кошельке недостаточно\nкрипты для\u00A0оплаты'}</H4>
        </TitleWrapper>

        <TipWrapper>
          <CaptionText500>
            {'Введите меньшую сумму и\u00A0попробуйте\u00A0ещё\u00A0раз'}
          </CaptionText500>
        </TipWrapper>

        <ButtonWrapper>
          <Button
            width={'100%'}
            size={'m'}
            type={'primary'}
            onPress={() =>
              redirectTo(`../${props.payForm.application?.shopCode}`)
            }
          >
            Изменить сумму
          </Button>
        </ButtonWrapper>
        <BackContainer onClick={appClose}>
          <BodyText600>Вернуться в QWIK</BodyText600>
        </BackContainer>
      </Container>
    </FlexBoxCenteredLayout>
  )
}

const mapStateToProps = (state: RootState): StateProps => ({
  payForm: state.payForm,
})

export default connect(mapStateToProps)(ShopInsufficientFundsPage)
