import { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Button, Input } from '@pijma/crypto'
import { useBaseMask, usePhoneMask } from '@pijma/input'

import { Dispatch, RootState } from '@stores'
import { TPayFormState } from '@stores/types/*'
import {
  charIsRusLetterWithSpaces,
  nameSurnameRuRegexp,
  phoneRegexp,
  replaceNotRusSymbols,
  symbolNameRegexp,
} from '@utils'
import styled from 'styled-components'

import {
  SESSION_PHONE_KEY,
  SESSION_SENDER_KEY,
} from '../../../../../stores/payform/payForm'
import InputValidation from '../../../../../validation/InputValidation'
import { H4 } from '../../../../common/header/Headings'
import { Island } from '../../../../common/island'
import NavigationBar from '../../../../common/navigation/NavigationBar'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 20px;
`

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0 20px 24px;
`

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 20px 20px;
`

const SourceTitle = styled.div`
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
  width: 100%;
  padding: 20px 24px 16px;
`

const NavigationBarWrapper = styled.div`
  padding: 0 4px;
  display: flex;
  width: 100%;
  align-items: start;
`

interface StateProps {
  payForm: TPayFormState
}

interface DispatchProps {
  setPhone: (phone: string) => void
  setSender: (sender: string) => void
  doPaymentBlocking: () => void
  resetPaymentBlocking: () => void
}

type Props = StateProps & DispatchProps

const PurchaseSourceSbpPage = (props: Props) => {
  const redirectTo = useNavigate()
  // validation
  //// state
  const [phoneValidation, setPhoneValidation] = useState<InputValidation>({
    valid: true,
  })
  const [senderValidation, setSenderValidation] = useState<InputValidation>({
    valid: true,
  })
  const [formValid, setFormValid] = useState<boolean>(false)
  //// effects
  const validatePhone = useCallback(
    (onlyValid = false) => {
      if (props.payForm.phone && phoneRegexp.test(props.payForm.phone)) {
        setPhoneValidation({ valid: true })
        return true
      }
      if (onlyValid) return false
      setPhoneValidation({
        valid: false,
        message: 'Введите 10 цифр',
      })
      return false
    },
    [props.payForm.phone],
  )
  const validateSender = useCallback(
    (onlyValid = false) => {
      if (
        props.payForm.sender &&
        nameSurnameRuRegexp.test(props.payForm.sender) &&
        !symbolNameRegexp.test(props.payForm.sender)
      ) {
        setSenderValidation({ valid: true })
        return true
      }
      if (onlyValid) return false
      if (props.payForm.sender == undefined) {
        setSenderValidation({
          valid: false,
          message: 'Введите имя и фамилию',
        })
        return false
      }
      setSenderValidation({
        valid: false,
        message: 'Это не похоже на имя и фамилию',
      })
      return false
    },
    [props.payForm.sender],
  )

  useEffect(() => {
    if (props.payForm.phone) {
      validatePhone(true)
    } else {
      setFormValid(false)
    }
  }, [props.payForm.phone, validatePhone])

  useEffect(() => {
    if (props.payForm.sender) {
      validateSender(true)
    } else {
      setFormValid(false)
    }
  }, [props.payForm.sender, validateSender])

  useEffect(() => {
    setFormValid(
      props.payForm.sender !== undefined &&
        props.payForm.phone !== undefined &&
        phoneValidation.valid &&
        senderValidation.valid,
    )
  }, [
    phoneValidation,
    senderValidation,
    props.payForm.sender,
    props.payForm.phone,
  ])

  // Pijma input masks
  const phoneMask = usePhoneMask({
    mask: {
      onBlur: () => {
        validatePhone()
      },
      value: props.payForm.phone,
      onValueChange: ({ formattedValue }) => {
        props.setPhone(formattedValue)
      },
    },
    country: 'RU',
  })
  const senderMask = useBaseMask({
    type: 'text',
    format: replaceNotRusSymbols,
    isValidInputCharacter: charIsRusLetterWithSpaces,
    removeFormatting: replaceNotRusSymbols,
    value: props.payForm.sender,
    onBlur: () => {
      validateSender()
    },
    onValueChange: ({ formattedValue }) => {
      props.setSender(formattedValue)
    },
  })

  const goToSumPage = () => {
    validatePhone()
    validateSender()
    sessionStorage.setItem(SESSION_PHONE_KEY, props.payForm.phone + '')
    sessionStorage.setItem(SESSION_SENDER_KEY, props.payForm.sender + '')
    redirectTo(`../../sum`)
  }

  return (
    <Container>
      <NavigationBarWrapper>
        <NavigationBar
          paymentMethodRedirectLink={'../../source-select'}
          paymentDisabled
          sumDisabled
        />
      </NavigationBarWrapper>
      <Island size="M" bgColor="primary">
        <SourceTitle data-testid="userdata.header">
          <H4>С какого номера вы будете делать&nbsp;перевод?</H4>
        </SourceTitle>
        <InputContainer>
          <Input
            {...phoneMask}
            label={`Номер телефона`}
            width={'100%'}
            disabled={props.payForm.paymentBlocking}
            type={'text'}
            placeholder={'+7 (000) 000-00-00'}
            errorMessage={phoneValidation.message}
            invalid={!phoneValidation.valid}
            allowClear={true}
            data-testid="userdata.input.phone"
          />
        </InputContainer>
        <InputContainer>
          <Input
            {...senderMask}
            label={`Отправитель`}
            width={'100%'}
            disabled={props.payForm.paymentBlocking}
            type={'text'}
            description={
              'Продавец поймёт, от\u00A0кого пришёл перевод, и\u00A0отправит крипту быстрее'
            }
            placeholder={'Например, Антон Чехов'}
            errorMessage={senderValidation.message}
            invalid={!senderValidation.valid}
            allowClear={true}
            data-testid="userdata.input.name"
          />
        </InputContainer>
        <ButtonContainer>
          <Button
            width={'100%'}
            size={'m'}
            disabled={!formValid}
            onPress={() => goToSumPage()}
            data-testid="userdata.button.forward"
          >
            К вводу суммы
          </Button>
        </ButtonContainer>
      </Island>
    </Container>
  )
}

const mapStateToProps = (state: RootState): StateProps => ({
  payForm: state.payForm,
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    setPhone: (phone: string) =>
      dispatch.payForm.set({
        phone,
      }),
    setSender: (sender: string) =>
      dispatch.payForm.set({
        sender,
      }),
    doPaymentBlocking: () =>
      dispatch.payForm.set({
        paymentBlocking: true,
      }),
    resetPaymentBlocking: () =>
      dispatch.payForm.set({
        paymentBlocking: false,
      }),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PurchaseSourceSbpPage)
