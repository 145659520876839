declare global {
  interface Window {
    TelegramWebviewProxy?: () => void
  }
}
export const isInTelegramRuntime = window.TelegramWebviewProxy
export const initTg = () => {
  if (isInTelegramRuntime) {
    window.Telegram?.WebApp.BackButton.onClick(() => history.back())
  }
}
