import { FC } from 'react'

import { ErrorPage } from './ErrorPage'

export const InterruptPaymentPage: FC = () => (
  <ErrorPage
    title={'Мы\u00A0прервали покупку'}
    tip={
      'Вас не\u00A0было тут больше 15\u00A0минут.\n' +
      'Мы\u00A0беспокоимся за\u00A0безопасность вашего портфеля, поэтому прервали покупку.\n' +
      'Чтобы попробовать ещё раз, вернитесь в\u00A0кошелёк'
    }
  />
)
