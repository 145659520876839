import { useNavigate } from 'react-router-dom'

import { IconTm } from '@pijma/icon-gallery/countries-circle'
import { IconCloseSmall } from '@pijma/icon-gallery/crypto'

import { useToken } from '@hooks'
import styled from 'styled-components'

import { Color } from '../../../../../generated/quicktype/Tokens'
import { EShop } from '../../../../stores/types/EShop'
import { H3Weight600 } from '../../../common/header/Headings'
import { FlexBoxCenteredLandingLayout } from '../../../common/layout/flex-box/FlexBoxCenteredLandingLayout'
import { BodyText500 } from '../../../common/text/BodyText'
import iconAM from './assets/iconAM.svg'
import iconAZ from './assets/iconAZ.svg'
import iconBY from './assets/iconBY.svg'
import iconKG from './assets/iconKG.svg'
import iconKZ from './assets/iconKZ.svg'
import iconMD from './assets/iconMD.svg'
import iconRU from './assets/iconRU.svg'
import iconTJ from './assets/iconTJ.svg'
import iconUZ from './assets/iconUZ.svg'

const Header = styled.div<Color>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background-color: ${(color) => color.bg.page.value};
  margin-top: 18px;
  margin-bottom: 24px;
  padding: 0 4px;
`

const IslandBlock = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 12px;
  font-weight: 600;
  justify-content: space-between;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  max-width: 420px;
`

const CloseIconWrapper = styled.div<{ shadow: string }>`
  cursor: pointer;
  height: 32px;
  width: 32px;
  position: fixed;
  top: 16px;
  right: 16px;
  align-content: center;
  text-align: center;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: ${(styleProps) => styleProps.shadow};
`

const CountryListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  align-self: stretch;
`

const CountryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`

export const HelpSngListPage = () => {
  const redirectTo = useNavigate()
  const crossShadow = useToken().shadow.z2.value

  return (
    <FlexBoxCenteredLandingLayout>
      <Container>
        <Header {...useToken().color}>
          <IslandBlock>
            <H3Weight600>Страны СНГ</H3Weight600>
            <CloseIconWrapper
              shadow={`${crossShadow.x} ${crossShadow.y} ${crossShadow.blur} ${crossShadow.spread} ${crossShadow.color}`}
            >
              <IconCloseSmall
                onClick={() => redirectTo(`../${EShop.STEAM}`)}
                height={24}
                width={24}
              />
            </CloseIconWrapper>
          </IslandBlock>
          <BodyText500>
            Пополняйте аккаунт только этих стран. Другие, например, Турция,
            Америка и&nbsp;прочие, пока&nbsp;недоступны.
          </BodyText500>
        </Header>
        <CountryListWrapper>
          <CountryWrapper>
            <img alt={'AZ'} width={48} height={48} src={iconAZ} />
            <BodyText500>Азербайджан</BodyText500>
          </CountryWrapper>
          <CountryWrapper>
            <img alt={'AM'} width={48} height={48} src={iconAM} />
            <BodyText500>Армения</BodyText500>
          </CountryWrapper>
          <CountryWrapper>
            <img alt={'BY'} width={48} height={48} src={iconBY} />
            <BodyText500>Беларусь</BodyText500>
          </CountryWrapper>
          <CountryWrapper>
            <img alt={'MD'} width={48} height={48} src={iconMD} />
            <BodyText500>Молдова</BodyText500>
          </CountryWrapper>
          <CountryWrapper>
            <img alt={'RU'} width={48} height={48} src={iconRU} />
            <BodyText500>Россия</BodyText500>
          </CountryWrapper>
          <CountryWrapper>
            <img alt={'KZ'} width={48} height={48} src={iconKZ} />
            <BodyText500>Казахстан</BodyText500>
          </CountryWrapper>
          <CountryWrapper>
            <img alt={'TJ'} width={48} height={48} src={iconTJ} />
            <BodyText500>Таджикистан</BodyText500>
          </CountryWrapper>
          <CountryWrapper>
            <img alt={'KG'} width={48} height={48} src={iconKG} />
            <BodyText500>Киргизия</BodyText500>
          </CountryWrapper>
          <CountryWrapper>
            <img alt={'UZ'} width={48} height={48} src={iconUZ} />
            <BodyText500>Узбекистан</BodyText500>
          </CountryWrapper>
          <CountryWrapper>
            <IconTm width={48} height={48} />
            <BodyText500>Туркмения</BodyText500>
          </CountryWrapper>
        </CountryListWrapper>
      </Container>
    </FlexBoxCenteredLandingLayout>
  )
}
