import { ECurrency, isoToECurrency } from '@stores/types/ECurrency'

import {
  EApplicationType,
  stringToEApplicationType,
} from '../../stores/types/EApplicationType'
import { EShop, stringToEShop } from '../../stores/types/EShop'
import { codeToStatus, EApplicationStatus } from './EApplicationStatus'
import { TApplicationResponse } from './TApplicationResponse'

export type TApplication = {
  currency: ECurrency
  applicationUuid: string
  userPhoneNumber: string
  userId: string
  applicationExtId: string
  expirationDate: Date
  minLimit: string
  maxLimit: string
  status: EApplicationStatus
  creationDate: Date
  paymentUrl: string
  type: EApplicationType
  shopCode: EShop
}

export const fromResponse = (res: TApplicationResponse): TApplication => {
  return {
    currency: isoToECurrency(res.currency),
    applicationUuid: res.application_uuid,
    userPhoneNumber: res.user_phone_number,
    userId: res.user_id,
    applicationExtId: res.application_ext_id,
    expirationDate: res.expiration_date,
    minLimit: res.min_limit,
    maxLimit: res.max_limit,
    status: codeToStatus(res.status_code),
    creationDate: res.creation_date,
    paymentUrl: res.payment_url,
    type: stringToEApplicationType(res.application_type),
    shopCode: res.shop_code ? stringToEShop(res.shop_code) : null,
  } as TApplication
}
