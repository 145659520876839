import { FC, useEffect } from 'react'

import { env, QWIK_RETURN_URL } from '@utils'

import { isInTelegramRuntime } from '../../../../../../tg-init'

export const ClosePage: FC = () => {
  useEffect(() => {
    const successUrl = sessionStorage.successUrl

    if (successUrl) {
      location.assign(successUrl)
    } else if (isInTelegramRuntime) {
      window.Telegram.WebApp.close()
    } else {
      location.assign(env(QWIK_RETURN_URL) as string)
    }
  }, [])

  return <></>
}
