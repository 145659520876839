import * as jsonParser from 'lossless-json'
import { BACKEND_URL, env } from '@utils'
import axios, { AxiosHeaders, AxiosInstance } from 'axios'

import { EHttpMethod } from './enums'

const axiosConfig = {
  baseURL: env(BACKEND_URL) || '',
  withCredentials: true,
}

// Prepare clients
const axiosClient: AxiosInstance = axios.create(axiosConfig)

export interface IHttpClient {
  request<Res>(
    method: EHttpMethod,
    url: string,
    headers?: object,
    data?: object,
  ): Promise<Res>

  post<Res>(url: string, data?: object, headers?: object): Promise<Res>
  put<Res>(url: string, data?: object, headers?: object): Promise<Res>
  get<Res>(url: string, headers?: object): Promise<Res>
}

const httpClientFactory = (client: AxiosInstance): IHttpClient => {
  return {
    request<Res>(
      method: EHttpMethod,
      url: string,
      headers?: object,
      data?: object,
    ): Promise<Res> {
      const config = {
        method: method.toString(),
        data: data,
        url: url,
        headers: headers,
        validateStatus: (status: number) => status < 400,
        transformResponse: [
          (res: string, headers: AxiosHeaders) => {
            const contentType = headers.get('content-type')
            return contentType &&
              contentType.toString().includes('application/json')
              ? jsonParser.parse(res, null, (number) => number)
              : res
          },
        ],
      }
      return client
        .request(config)
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          throw error
        })
    },
    post<Res>(url: string, data?: object, headers?: object): Promise<Res> {
      return this.request(EHttpMethod.POST, url, headers, data)
    },
    put<Res>(url: string, data?: object, headers?: object): Promise<Res> {
      return this.request(EHttpMethod.PUT, url, headers, data)
    },
    get<Res>(url: string, headers?: object): Promise<Res> {
      return this.request(EHttpMethod.GET, url, headers)
    },
  }
}

export const httpClient = httpClientFactory(axiosClient)
