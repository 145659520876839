import { useEffect, useReducer } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'

import { EPaymentMethod } from '@api/types'
import { RootState } from '@stores'
import { TPayFormState } from '@stores/types/*'

import { commonApplicationClient } from '../../../api/clients/common-application-client'
import { shopApplicationClient } from '../../../api/clients/shop-application-client'
import { payFormService, personalDataService } from '../../../service/payform'
import {
  applicationReducer,
  ApplicationReducerActions,
  TApplicationState,
} from '../../../stores/reducers/ApplicationReducer'
import { EApplicationType } from '../../../stores/types/EApplicationType'
import { EShop } from '../../../stores/types/EShop'
import { Outlet } from '../../common/Outlet'

interface StateProps {
  payForm: TPayFormState
}

interface PersonalDataReducerAction {
  userId: string
  shop: EShop
}

interface PersonalDataState {
  fetched: boolean
}

const personalDataReducer = (
  state: PersonalDataState,
  action: PersonalDataReducerAction,
) => {
  if (!state.fetched) {
    commonApplicationClient
      .personalData(
        action.userId,
        EPaymentMethod.QWIK,
        EApplicationType.PAYMENT,
        action.shop,
      )
      .then((res) => personalDataService.init(res))
    return { fetched: true }
  }
  return state
}

const ShopWrapperPage = (props: StateProps) => {
  const initialApplication: TApplicationState = { applicationId: '' }
  const initialShop: TShopState = { fetched: false }
  const initialPersonalData = { fetched: false }
  const applicationId = useParams().applicationId

  const [, applicationDispatch] = useReducer(
    applicationReducer,
    initialApplication,
  )

  const [, personalDataDispatch] = useReducer(
    personalDataReducer,
    initialPersonalData,
  )

  const [, shopDispatch] = useReducer(shopReducer, initialShop)

  useEffect(() => {
    if (!applicationId || applicationId === 'undefined') {
      return
    } else {
      applicationDispatch({
        action: ApplicationReducerActions.GET,
        applicationId: applicationId,
      })
    }
  }, [applicationId])

  useEffect(() => {
    const shop = props.payForm?.application?.shopCode
    if (shop) {
      shopDispatch({
        shop,
      })
    }
  }, [props.payForm?.application?.shopCode])

  useEffect(() => {
    if (
      props.payForm.application?.userId &&
      props.payForm.application.shopCode
    ) {
      personalDataDispatch({
        userId: props.payForm.application?.userId,
        shop: props.payForm.application.shopCode,
      })
    }
  }, [props.payForm.application?.userId, props.payForm.application?.shopCode])

  return (
    <>
      <Outlet />
    </>
  )
}

export interface TShopState {
  fetched: boolean
}

export interface ShopReducerAction {
  shop: EShop
}

export const shopReducer = (state: TShopState, action: ShopReducerAction) => {
  if (!state.fetched) {
    shopApplicationClient
      .shopInfo(action.shop)
      .then((res) => payFormService.initShopInfo(res))
    return { fetched: true }
  }
  return state
}

const mapStateToProps = (state: RootState): StateProps => ({
  payForm: state.payForm,
})

export default connect(mapStateToProps)(ShopWrapperPage)
