import { useNavigate, useParams } from 'react-router-dom'

import { Button } from '@pijma/crypto'
import { IconRain } from '@pijma/icon-gallery/crypto'

import styled from 'styled-components'

import { H4 } from '../../../common/header/Headings'
import { FlexBoxCenteredLayout } from '../../../common/layout/flex-box'
import { CaptionText500 } from '../../../common/text/BodyText'
import { BodyText600 } from '../../../common/text/BodyText'

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`

const TitleWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
  white-space: pre-wrap;
`

const DescriptionWrapper = styled.div`
  white-space: pre-wrap;
  margin-bottom: 32px;
`

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 102px 0 102px;
`

const BackContainer = styled.div`
  white-space: pre-wrap;
  margin-top: 24px;
  color: #217aac;

  span {
    cursor: pointer;
    :hover {
      color: rgb(49, 49, 49);
    }
  }
`

export const PurchaseOffersNotFoundPage = () => {
  const redirectTo = useNavigate()
  const pathParams = useParams()
  const applicationId = pathParams.applicationId as string

  const returnToFindOffersPage = () => {
    redirectTo(`../../application/${applicationId}/purchase/find-offers`)
  }

  const returnToSumPage = () => {
    redirectTo(`../../application/${applicationId}/purchase/sum`)
  }

  return (
    <FlexBoxCenteredLayout>
      <Container>
        <IconRain width="61px" height="61" />

        <TitleWrapper>
          <H4>{'Увы, сейчас нет\nничего подходящего'}</H4>
        </TitleWrapper>

        <DescriptionWrapper>
          <CaptionText500>
            {'Попробуйте еще раз или\nзайдите через 10 минут'}
          </CaptionText500>
        </DescriptionWrapper>

        <ButtonContainer>
          <Button
            width={'100%'}
            size={'m'}
            type={'primary'}
            onPress={() => returnToFindOffersPage()}
          >
            Поискать еще раз
          </Button>
        </ButtonContainer>

        <BackContainer onClick={() => returnToSumPage()}>
          <BodyText600>Назад</BodyText600>
        </BackContainer>
      </Container>
    </FlexBoxCenteredLayout>
  )
}
