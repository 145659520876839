export enum ECurrency {
  USD = 'USD',
  AED = 'AED',
  RUB = 'RUB',
}

export const stringToECurrency = (str: string) =>
  ECurrency[str.toUpperCase() as keyof typeof ECurrency]

export const isoToECurrency = (str: string) => {
  switch (str) {
    case '840':
      return ECurrency.USD
    case '784':
      return ECurrency.AED
    case '643':
      return ECurrency.RUB
    default:
      return null
  }
}
