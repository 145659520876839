export interface Tokens {
  color: Color
  text: Text
  border_radius: BorderRadius
  height: Height
  horizontal_padding: HorizontalPadding
  shadow: Shadow
  size: Size
  border: Border
  vertical_padding: VerticalPadding
  animation: Animation
  version: Version
}

export interface Animation {
  s: Version
  m: Version
}

export interface Version {
  value: string
  type: string
  description: string
}

export interface Border {
  btn: BorderBtn
  check_box: BorderCheckBox
  input: BorderInput
  chips: BorderChips
  big_block: BigBlock
  menu_item: BorderMenuItem
}

export interface BigBlock {
  outlined: Outlined
}

export interface Outlined {
  default: Checked
}

export interface Checked {
  value: CheckedValue
  type: FocusType
}

export enum FocusType {
  Border = 'border',
}

export interface CheckedValue {
  color: string
  width: Width
}

export enum Width {
  The1Px = '1px',
}

export interface BorderBtn {
  secondary: BtnSecondary
}

export interface BtnSecondary {
  default: Checked
  hover: Checked
  disabled: Checked
}

export interface BorderCheckBox {
  default: Checked
  hover: Checked
  checked: Checked
  disabled: Checked
  error: Checked
}

export interface BorderChips {
  default: Checked
  disabled: Checked
}

export interface BorderInput {
  default: Checked
  disabled: Checked
  error: Checked
  focus: Checked
}

export interface BorderMenuItem {
  secondary: PurpleSecondary
}

export interface PurpleSecondary {
  selected: Checked
}

export interface BorderRadius {
  s: L
  m: L
  l: L
  text?: L
}

export interface L {
  value: string
  type: LType
}

export enum LType {
  BorderRadius = 'borderRadius',
  Color = 'color',
  Sizing = 'sizing',
}

export interface Color {
  bg: Bg
  font: Font
  icon: ColorIcon
  common: Common
}

export interface Bg {
  page: L
  btn: BgBtn
  spinner: L
  circle_btn: CircleBtn
  check_box: PrimaryClass
  island: Island
  chips: LinkClass
  input: SecondaryClass
  scroll: Scroll
  list_item: BgListItem
  drawer: Drawer
  menu_item: BgMenuItem
}

export interface BgBtn {
  secondary: PrimaryClass
  primary: PrimaryClass
}

export interface PrimaryClass {
  default: L
  hover: L
  disabled: L
  checked?: L
}

export interface LinkClass {
  default: L
  hover: L
}

export interface CircleBtn {
  secondary: Scroll
}

export interface Scroll {
  default: L
}

export interface Drawer {
  default: L
  dimmer: L
}

export interface SecondaryClass {
  default: L
  disabled: L
}

export interface Island {
  filled: Filled
}

export interface Filled {
  primary: L
  secondary: L
}

export interface BgListItem {
  start_gradient: L
  white_bg: WhiteBgClass
  color_bg: WhiteBgClass
}

export interface WhiteBgClass {
  hover: L
}

export interface BgMenuItem {
  secondary: FluffySecondary
}

export interface FluffySecondary {
  hover: L
  selected: L
}

export interface Common {
  primary: Ary
  secondary: Ary
}

export interface Ary {
  error: L
  success: L
  waiting: L
}

export interface Font {
  light_bg: DarkBgClass
  dark_bg: DarkBgClass
  link: LinkClass
}

export interface DarkBgClass {
  secondary: SecondaryClass
  primary: SecondaryClass
  label: Scroll
}

export interface ColorIcon {
  light_bg: LightBg
  dark_bg: DarkBg
}

export interface DarkBg {
  secondary: SecondaryClass
  primary: SecondaryClass
}

export interface LightBg {
  primary: SecondaryClass
  secondary: PrimaryClass
}

export interface Height {
  btn: BorderRadius
  chips: TextInputClass
  text_input: TextInputClass
  list_item: HeightListItem
}

export interface TextInputClass {
  desktop: L
}

export interface HeightListItem {
  m: L
  l: L
}

export interface HorizontalPadding {
  btn: HorizontalPaddingBtn
  drawer: Accordion
  accordion: Accordion
}

export interface Accordion {
  m: L
}

export interface HorizontalPaddingBtn {
  general: General
  text: Accordion
}

export interface General {
  m: L
  s: L
}

export interface Shadow {
  z1: Z1
  z2: Z1
}

export interface Z1 {
  value: Z1Value
  type: string
}

export interface Z1Value {
  x: string
  y: string
  blur: string
  spread: string
  color: string
  type: string
}

export interface Size {
  icon: SizeIcon
}

export interface SizeIcon {
  btn: L
  chips: L
  input: L
  list_item: HeightListItem
  vertical_text_to_copy: L
}

export interface Text {
  body: { [key: string]: Body }
  accent: { [key: string]: H1 }
  h1: H1
  h2: H1
  h3: H1
  caption: { [key: string]: H1 }
}

export interface H1 {
  value: H1Value
  type: H1Type
}

export enum H1Type {
  Typography = 'typography',
}

export interface H1Value {
  fontFamily: FontFamily
  fontWeight: string
  lineHeight: string
  fontSize: string
}

export enum FontFamily {
  FontFamilyInter = 'inter',
  Inter = 'Inter',
}

export interface Body {
  default: H1
  long?: H1
}

export interface VerticalPadding {
  accordion: Accordion
}
