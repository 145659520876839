import { FC, ReactElement } from 'react'

import { Spinner } from '@pijma/crypto'

import styled from 'styled-components'

import { FlexBoxCenteredLayout } from '../flex-box'

interface SpinnerLayoutProps {
  children?: ReactElement
}

const SpinnerWrapper = styled.div`
  margin-bottom: 16px;
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const SpinnerLayout: FC<SpinnerLayoutProps> = ({ children }) => {
  return (
    <FlexBoxCenteredLayout>
      <Container>
        <SpinnerWrapper>
          <Spinner size={28} />
        </SpinnerWrapper>
        {children}
      </Container>
    </FlexBoxCenteredLayout>
  )
}
