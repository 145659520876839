import { FC, ReactElement } from 'react'

import { useToken } from '@hooks'
import styled from 'styled-components'

import { Color } from '../../../../../generated/quicktype/Tokens'
import { GlobalStyle } from '../../global-style'

export interface FlexBoxProps {
  children?: ReactElement
}

const MainWrap = styled.div<Color>`
  width: 100dvw;
  height: 100dvh;
  display: flex;
  justify-content: center;
  padding-bottom: 7dvh;
  background-color: ${(color) => color.bg.page.value};
`
const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 1920px;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
`
export const FlexBoxCenteredLayout: FC<FlexBoxProps> = (props) => (
  <MainWrap {...useToken().color}>
    <GlobalStyle />
    <Container>{props.children} </Container>
  </MainWrap>
)
