import { FC, useEffect, useState } from 'react'

import { IconArrowDown } from '@pijma/icon-gallery/crypto'

import { useToken } from '@hooks'
import styled from 'styled-components'

import { TextProps } from '../../text/TextProps'
import { TextSpan } from '../../text/TextSpan'
import { InfoSearchData } from './InfoSearchData'
import Modal from './Modal'

const ModalSelectInputWrapper = styled.div`
  position: relative;
`

const ModalSelectInputElement = styled.div`
  box-sizing: border-box;
  border: 1px solid rgb(217, 217, 217);
  border-radius: 16px;
  display: flex;
  -webkit-box-align: center;
  vertical-align: middle;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 12px 16px;
  cursor: pointer;
  background-color: white;

  :hover {
    background-color: aliceblue;
  }
`

const SelectedElementWrapper = styled.div`
  height: 44px;
  line-height: 44px;
  display: flex;
  -webkit-box-align: center;
  vertical-align: middle;
  align-items: center;
`

const SelectedImg = styled.img`
  margin-right: 10px;
`

const ArrowWrapper = styled.div`
  line-height: 24px;
`

const Caption: FC<TextProps> = (props: TextProps) => (
  <TextSpan {...useToken().text.caption['500'].value} lineHeight={'20px'}>
    {props.children}
  </TextSpan>
)

interface ModalSelectInputProps {
  defaultIcon: string
  defaultText: string
  data: InfoSearchData[]
  searchTitle: string
  searchPlaceholder: string
  onSelect: (id: string) => void
  selected: string | undefined
}

export const ModalSelectInput = ({
  defaultIcon,
  defaultText,
  data,
  searchTitle,
  searchPlaceholder,
  onSelect,
  selected,
}: ModalSelectInputProps) => {
  const [selectedId, setSelectedId] = useState(selected)
  const [text, setText] = useState<string>(defaultText)
  const [icon, setIcon] = useState<string>(defaultIcon)
  const [isOpenModal, setOpenModal] = useState<boolean>(false)

  const onSearchElementSelect = (selected: InfoSearchData) => {
    onSelect(selected.id)
    setSelectedId(selected.id)
    setText(selected.name)
    setIcon(selected.logo)
    setOpenModal(false)
  }

  const openModal = () => {
    setOpenModal(true)
  }

  const closeModal = () => {
    setOpenModal(false)
  }

  useEffect(() => {
    if (selectedId && data) {
      const preSelected = data.find((item) => item.id == selectedId)
      if (preSelected) {
        setText(preSelected.name)
        setIcon(preSelected.logo)
      }
    }
  }, [selectedId, selected, data])

  return (
    <ModalSelectInputWrapper>
      <ModalSelectInputElement onClick={openModal}>
        <SelectedElementWrapper>
          <SelectedImg src={icon} width={44} height={44} />
          <Caption>{text}</Caption>
        </SelectedElementWrapper>
        <ArrowWrapper>
          <IconArrowDown width={24} height={24} color={'#868686'} />
        </ArrowWrapper>
      </ModalSelectInputElement>
      <Modal
        isOpen={isOpenModal}
        closeModal={closeModal}
        searchPlaceholder={searchPlaceholder}
        searchTitle={searchTitle}
        onSearchElementSelect={onSearchElementSelect}
        data={data}
      />
    </ModalSelectInputWrapper>
  )
}
