import { httpClient, IHttpClient } from '@api'

import { EShop } from '../../stores/types/EShop'
import { TShopInfo } from '../types/TShopInfo'
import { TShopPaymentResponse } from '../types/TShopPaymentResponse'

interface IShopApplicationClient {
  payment(
    applicationId: string,
    account: string,
    amount: string,
  ): Promise<TShopPaymentResponse>

  checkAccount(shop: EShop, account: string): Promise<void>

  shopInfo(shop: EShop): Promise<TShopInfo>
}

const shopApplicationClientFactory = (
  client: IHttpClient,
): IShopApplicationClient => {
  return {
    payment(
      applicationId: string,
      account: string,
      amount: string,
    ): Promise<TShopPaymentResponse> {
      return client.post(
        `/client/v1/payment-applications/${applicationId}/payment`,
        {
          accountName: account,
          amount: amount,
        },
      )
    },
    shopInfo(shop: EShop): Promise<TShopInfo> {
      return client.get(`/client/v1/payment-applications/shop/${shop}/info`)
    },
    checkAccount(shop: EShop, account: string): Promise<void> {
      return client.post(`/client/v1/payment-applications/check-account`, {
        accountName: account,
        shopCode: shop,
      })
    },
  }
}

export const shopApplicationClient: IShopApplicationClient =
  shopApplicationClientFactory(httpClient)
