import { TApplication, TPaymentProvider } from '@api/types'
import { store } from '@stores'
import { TPaymentProvidersState } from '@stores/types/*'

import { TNspkBanks } from '../../api/types/TNspkBanks'
import { TPaymentProviderResponse } from '../../api/types/TPaymentProviderResponse'
import { TPaymentReceiverInfoResponse } from '../../api/types/TPaymentReceiverInfoResponse'
import { TPersonalData } from '../../api/types/TPersonalData'
import { TSellSenderInfoResponse } from '../../api/types/TSellSenderInfoResponse'
import { TShopInfo } from '../../api/types/TShopInfo'
import { EPaymentProvider } from '../../stores/types/EPaymentProvider'

export const payFormService = {
  initPayForm(application: TApplication) {
    store.dispatch.payForm.init({ application })
  },
  initSellForm(provider: TPaymentProvider) {
    store.dispatch.payForm.set({
      paymentProvider: EPaymentProvider.RT,
    })
    store.dispatch.payForm.set({
      rate: provider.providerExchangeRate,
    })
    store.dispatch.payForm.set({
      minLimit: provider.providerLimit.minLimit.toString(),
      maxLimit: provider.providerLimit.maxLimit.toString(),
    })
  },
  initShopInfo(shop: TShopInfo) {
    store.dispatch.payForm.set({
      fee: +shop.commission,
      shopName: shop.shopName,
      minLimit: shop.limit.minLimit + '',
      maxLimit: shop.limit.maxLimit + '',
    })
  },
  initBySession(providers: TPaymentProvidersState) {
    store.dispatch.payForm.initBySession(providers)
  },
}

export const personalDataService = {
  init(data: TPersonalData) {
    store.dispatch.payForm.initPersonalData(data)
  },
}

export const providerService = {
  initPaymentMethods(paymentProviders: TPaymentProviderResponse) {
    store.dispatch.paymentProviders.init(paymentProviders)
  },
}

export const p2pPurchaseService = {
  initP2PReceiverInfo(paymentReceiver: TPaymentReceiverInfoResponse) {
    store.dispatch.p2pPurchase.initP2PReceiverInfo(paymentReceiver)
  },
}

export const p2pSellService = {
  initP2PSenderInfo(sellSender: TSellSenderInfoResponse) {
    store.dispatch.p2pSell.initP2PSenderInfo(sellSender)
  },
}

export const nspkBanksService = {
  init(nspkBanks: TNspkBanks[]) {
    store.dispatch.nspkBanks.init({ banks: nspkBanks })
  },
}
