import { useEffect, useReducer } from 'react'
import { useParams } from 'react-router-dom'

import { sellApplicationClient } from '@api'

import { p2pSellService } from '../../../service/payform'
import { Outlet } from '../../common/Outlet'

interface SellSenderState {
  fetched: boolean
}

const sellSenderReducer = (state: SellSenderState, sellId: string) => {
  if (!state.fetched) {
    sellApplicationClient
      .getSender(sellId)
      .then((res) => p2pSellService.initP2PSenderInfo(res))
    const now = new Date(Date.now())
    now.setMinutes(now.getMinutes() + 1)
    now.setSeconds(now.getSeconds() + 50)

    return { fetched: true }
  }
  return state
}

export const P2PSellWrapperPage = () => {
  const pathParams = useParams()
  const paymentId = pathParams.sellId as string

  const initialSellSender = { fetched: false }

  const [, sellSenderDispatch] = useReducer(
    sellSenderReducer,
    initialSellSender,
  )

  useEffect(() => {
    sellSenderDispatch(paymentId)
  }, [paymentId])

  return (
    <>
      <Outlet />
    </>
  )
}
