import { FC } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Button, Link, ToClipboard } from '@pijma/crypto'
import { usePhoneMask } from '@pijma/input'

import { topupApplicationClient } from '@api'
import { useToken } from '@hooks'
import { RootState } from '@stores'
import { localeNumber } from '@utils'
import styled from 'styled-components'

import { DarkBgClass } from '../../../../../generated/quicktype/Tokens'
import { TPurchaseReceiverInfoState } from '../../../../stores/types/TPurchaseReceiverInfoState'
import { H3Weight600, H4 } from '../../../common/header/Headings'
import { Island } from '../../../common/island'
import { FlexBoxCenteredLayout } from '../../../common/layout/flex-box'
import NavigationBar from '../../../common/navigation/NavigationBar'
import { TextProps } from '../../../common/text/TextProps'
import { TextSpan } from '../../../common/text/TextSpan'
import { Timer } from '../../../common/timer'
import iconMan from './assets/iconMan.svg'

const HeaderBlock = styled.div`
  margin-top: 20px;
  margin-bottom: 12px;
  margin-left: 4px;
  font-weight: 600;
`

const Container = styled.div<DarkBgClass>`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  color: ${(bgClass) => bgClass.primary.default.value};
  padding: 0 20px;
`

const TraderBlock = styled.div`
  display: flex;
  width: 100%;
  margin: 8px 0;
`

const TraderBlockTitle = styled.div`
  margin: 12px 0 8px;
`

const ConfirmPaymentBlock = styled.div`
  width: 100%;
  margin: 33px 0 0;
`

const ConfirmPaymentBlockTitle = styled.div`
  margin-bottom: 8px;
`

const CancelTheDealBlock = styled.div`
  text-align: center;
  width: 100%;
  margin: 24px 0 0;
  color: #f35b1a;

  a {
    color: #f35b1a;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 24px 0 0;
`

const IslandPadding = styled.div`
  padding: 16px 20px 20px;
`

const ReceiverDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 8px;
  flex: 1 0 0;
  width: auto;
  vertical-align: middle;
`

const TextContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  vertical-align: middle;
`
const SimpleTextBlock = styled.div`
  color: #868686;
`

const LabelText = styled(SimpleTextBlock)`
  padding: 2px 0;
`

const OuterLabelText = styled.div`
  color: #868686;
  margin-left: 4px;
`

const GreenText = styled.div`
  color: #7ca525;
`

const TextManIcon = styled.img`
  margin: 0 4px -2px 0;
  vertical-align: text-bottom;
`

const NavigationBarWrapper = styled.div`
  padding-left: 4px;
  display: flex;
  width: 100%;
  align-items: start;
`

const TraderInfoText = styled(TextSpan)`
  width: 70%;
  text-align: right;
`

const TraderInfoCaption: FC<TextProps> = (props: TextProps) => (
  <TraderInfoText {...useToken().text.caption['500'].value} lineHeight={'24px'}>
    {props.children}
  </TraderInfoText>
)

const Caption: FC<TextProps> = (props: TextProps) => (
  <TextSpan {...useToken().text.caption['500'].value} lineHeight={'20px'}>
    {props.children}
  </TextSpan>
)

interface StateProps {
  receiver: TPurchaseReceiverInfoState
}

type Props = StateProps

const TimerDisplayText = (timeLeft: number) => {
  const minutes = Math.floor(timeLeft / (1000 * 60))
  const seconds = Math.floor((timeLeft / 1000) % 60)

  const displayMinutes = minutes < 10 ? `0${minutes}` : minutes
  const displaySeconds = seconds < 10 ? `0${seconds}` : seconds
  return `Оплатите в течение ${displayMinutes}:${displaySeconds}`
}

const P2PPurchasePage = (props: Props) => {
  const redirectTo = useNavigate()
  const tokens = useToken()

  const phoneMasked = usePhoneMask({
    mask: {
      value: props.receiver.phoneNumber?.replace('+7', ''), // избавляемся от +7 вначале что правильно маска отработала
    },
    country: 'RU',
  }).value

  const confirmPayment = (invoiceId: number) => {
    topupApplicationClient
      .confirmPayment(invoiceId)
      .then(() => {
        redirectTo('../pending')
      })
      .catch((error) => {
        console.log('Failed to confirm payment as paid', error)
      })
  }

  return (
    <>
      <FlexBoxCenteredLayout>
        <Container {...tokens.color.font.light_bg}>
          <HeaderBlock>
            <H3Weight600 data-testid="providers.header">
              Покупка USDT TRC20
            </H3Weight600>
          </HeaderBlock>
          <NavigationBarWrapper>
            <NavigationBar paymentMethodDisabled dataDisabled sumDisabled />
          </NavigationBarWrapper>
          <OuterLabelText>
            <Caption data-testid="bill-page.invoice-id">
              Сделка №{props.receiver.invoiceId}
            </Caption>
          </OuterLabelText>
          <TraderBlock>
            <Island size="M" bgColor="primary">
              <IslandPadding>
                <GreenText>
                  <Caption>
                    <TextManIcon
                      src={iconMan}
                      width={11}
                      height={24}
                      alt={'go to'}
                    />
                    <Caption>Откройте приложение банка</Caption>
                  </Caption>
                </GreenText>
                <TraderBlockTitle>
                  <H4>Сделайте перевод</H4>
                </TraderBlockTitle>
                <ReceiverDetails>
                  <TextContainer data-testid="bill-page.amount">
                    <LabelText>
                      <Caption>Сколько</Caption>
                    </LabelText>
                    {props.receiver.amount && (
                      <ToClipboard text={props.receiver.amount?.toString()}>
                        <TraderInfoCaption>
                          {localeNumber(`${props.receiver.amount}`)} &#8381;
                        </TraderInfoCaption>
                      </ToClipboard>
                    )}
                  </TextContainer>
                  <TextContainer data-testid="bill-page.receiver.phone">
                    <LabelText>
                      <Caption>Куда</Caption>
                    </LabelText>
                    {props.receiver.phoneNumber && (
                      <ToClipboard text={props.receiver.phoneNumber}>
                        <TraderInfoCaption>{phoneMasked}</TraderInfoCaption>
                      </ToClipboard>
                    )}
                  </TextContainer>
                  <TextContainer data-testid="bill-page.receiver.bank">
                    <LabelText>
                      <Caption>Банк</Caption>
                    </LabelText>
                    <TraderInfoCaption>{props.receiver.bank}</TraderInfoCaption>
                  </TextContainer>
                  <TextContainer data-testid="bill-page.receiver.fullname">
                    <LabelText>
                      <Caption>Продавец</Caption>
                    </LabelText>
                    <TraderInfoCaption>
                      {props.receiver.accountName}
                    </TraderInfoCaption>
                  </TextContainer>
                </ReceiverDetails>
              </IslandPadding>
            </Island>
          </TraderBlock>
          <OuterLabelText>
            {props.receiver.invoiceExpirationDate && (
              <Caption>
                <Timer
                  expirationDate={props.receiver.invoiceExpirationDate}
                  displayText={TimerDisplayText}
                />
              </Caption>
            )}
          </OuterLabelText>
          <ConfirmPaymentBlock>
            <Island size="M" bgColor="primary">
              <IslandPadding>
                <ConfirmPaymentBlockTitle>
                  <H4>
                    После перевода
                    <br />
                    подтвердите оплату
                  </H4>
                </ConfirmPaymentBlockTitle>
                <SimpleTextBlock>
                  <Caption>
                    И USDT поступят на счёт за&nbsp;10&nbsp;минут
                  </Caption>
                </SimpleTextBlock>
                <ButtonContainer data-testid="bill-page.button.confirm">
                  <Button
                    width={'100%'}
                    size={'m'}
                    onPress={() =>
                      props.receiver.invoiceId &&
                      confirmPayment(props.receiver.invoiceId)
                    }
                  >
                    Подтвердить оплату
                  </Button>
                </ButtonContainer>
              </IslandPadding>
            </Island>
          </ConfirmPaymentBlock>
          <CancelTheDealBlock data-testid="bill-page.button.cancel">
            <Link onPress={() => redirectTo('../confirm-cancel')}>
              Отменить сделку
            </Link>
          </CancelTheDealBlock>
        </Container>
      </FlexBoxCenteredLayout>
    </>
  )
}

const mapStateToProps = (state: RootState): StateProps => ({
  receiver: state.p2pPurchase,
})

export default connect(mapStateToProps)(P2PPurchasePage)
