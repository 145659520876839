import { createModel } from '@rematch/core'
import { RootModel } from '@stores'

import { TSellSenderInfoResponse } from '../../api/types/TSellSenderInfoResponse'
import { TSellSenderInfoState } from '../types/TSellSenderInfoState'

export const p2pSell = createModel<RootModel>()({
  state: {} as TSellSenderInfoState,
  reducers: {
    set(state, payload: TSellSenderInfoState) {
      return { ...state, ...payload }
    },
  },
  effects: (dispatch) => ({
    initP2PSenderInfo(payload: TSellSenderInfoResponse) {
      dispatch.p2pSell.set({ ...payload })
    },
  }),
})
