import { useEffect, useReducer } from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { EApplicationStatus } from '@api/types'
import { RootState } from '@stores'
import { TPayFormState } from '@stores/types/*'

import { appClose } from '../../../../helpers/route-utils'
import {
  applicationReducer,
  ApplicationReducerActions,
  TApplicationState,
} from '../../../../stores/reducers/ApplicationReducer'
import { EApplicationType } from '../../../../stores/types/EApplicationType'
import { DWalletTransfer } from '../dealer-wallet-transfer/DWalletTransfer'

interface StateProps {
  payForm: TPayFormState
}

const LOADING_VIEW_TIME = 2 * 1000

const PaymentPage = (props: StateProps) => {
  const initialApplication: TApplicationState = { applicationId: '' }

  const [, applicationDispatch] = useReducer(
    applicationReducer,
    initialApplication,
  )

  const location = useLocation()
  const redirectTo = useNavigate()
  const params = useParams()
  const applicationId = params.applicationId

  useEffect(() => {
    if (!applicationId || applicationId === 'undefined') {
      return
    } else {
      applicationDispatch({
        action: ApplicationReducerActions.GET,
        applicationId: applicationId,
      })
    }
  }, [applicationId])

  useEffect(() => {
    setTimeout(() => {
      if (!props.payForm.application?.status) return
      const type = props.payForm.application.type

      let redirect = null
      switch (type) {
        case EApplicationType.PURCHASE:
          redirect = purchaseRoute(props)
          break
        case EApplicationType.PAYMENT:
          redirect = shopRoute(props)
          break
        case EApplicationType.SELL:
          redirect = sellRoute(props)
          break
        default:
          throw new Error(`Unsupported application type: ${type}`)
      }
      if (redirect) {
        redirectTo(redirect, { replace: true })
      }
    }, LOADING_VIEW_TIME)
  }, [
    location.pathname,
    location.search,
    params.applicationId,
    redirectTo,
    props.payForm.application?.status,
    props.payForm.application?.paymentUrl,
    props,
  ])

  return <DWalletTransfer />
}

const purchaseRoute = (props: StateProps): string | null => {
  switch (props.payForm.application?.status) {
    case EApplicationStatus.PAYING: {
      window.location.href = props.payForm.application.paymentUrl
      break
    }
    case EApplicationStatus.FAIL:
    case EApplicationStatus.REJECT:
    case EApplicationStatus.PAID:
      appClose()
      break
    default: {
      return `/application/${props.payForm.application?.applicationUuid}/purchase/provider-select`
    }
  }
  return null
}

const sellRoute = (props: StateProps): string | null => {
  switch (props.payForm.application?.status) {
    case EApplicationStatus.PAYING: {
      window.location.href = props.payForm.application.paymentUrl
      break
    }
    case EApplicationStatus.FAIL:
    case EApplicationStatus.REJECT:
    case EApplicationStatus.PAID:
      appClose()
      break
    default: {
      return `/application/${props.payForm.application?.applicationUuid}/sell/source-select`
    }
  }
  return null
}

const shopRoute = (props: StateProps): string | null => {
  const status = props.payForm.application?.status
  switch (status) {
    case EApplicationStatus.READY_FOR_PAY: {
      return `/application/${props.payForm.application?.applicationUuid}/shop/${props.payForm.application?.shopCode}`
    }
    case EApplicationStatus.PAYING: {
      window.location.href = props.payForm.application?.paymentUrl || ''
      break
    }
    case EApplicationStatus.FAIL:
    case EApplicationStatus.REJECT:
      return `/application/${props.payForm.application?.applicationUuid}/shop/failure`
    case EApplicationStatus.PAID:
      return `/application/${props.payForm.application?.applicationUuid}/shop/success`
    default:
      throw new Error(`Unexpected shop status: ${status}`)
  }
  return null
}

const mapStateToProps = (state: RootState): StateProps => ({
  payForm: state.payForm,
})

export default connect(mapStateToProps)(PaymentPage)
