export enum EApplicationStatus {
  READY_FOR_PAY = 10,
  PAYING = 20,
  PAID = 30,
  REJECT = 40,
  FAIL = 50,
}

export function codeToStatus(code: string): EApplicationStatus {
  switch (code) {
    case '10':
      return EApplicationStatus.READY_FOR_PAY
    case '20':
      return EApplicationStatus.PAYING
    case '30':
      return EApplicationStatus.PAID
    case '40':
      return EApplicationStatus.REJECT
    case '50':
      return EApplicationStatus.FAIL
    default:
      throw new Error('Invalid status code')
  }
}
