import { createModel } from '@rematch/core'
import { RootModel } from '@stores'
import { TPaymentProvidersState } from '@stores/types/TPaymentMethodsState'

import { TPaymentProviderResponse } from '../../api/types/TPaymentProviderResponse'

export const paymentProviders = createModel<RootModel>()({
  state: {} as TPaymentProvidersState,
  reducers: {
    set(state, payload: TPaymentProvidersState) {
      return { ...state, ...payload }
    },
  },
  effects: (dispatch) => ({
    init(payload: TPaymentProviderResponse) {
      dispatch.paymentProviders.set({
        M3: payload.M3,
        RT: payload.RT,
      })
    },
  }),
})
