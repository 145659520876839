import { EPaymentMethod } from '@api/types'

export enum EPaymentSource {
  CARD = 'CARD',
  SBP = 'SBP',
}

export const paymentSourceByString = (value: string): EPaymentSource =>
  EPaymentSource[value.toUpperCase() as keyof typeof EPaymentSource]

export const toPaymentMethod = (source: EPaymentSource) => {
  switch (source) {
    case EPaymentSource.CARD:
      return EPaymentMethod.BANK_CARD
    case EPaymentSource.SBP:
      return EPaymentMethod.SBP
    default:
      throw new Error('unexpected EPaymentSource')
  }
}
