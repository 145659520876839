import { httpClient, IHttpClient } from '@api'

interface IAuthClient {
  getToken(authUuid: string): Promise<void>
}

const authClientFactory = (client: IHttpClient): IAuthClient => {
  return {
    getToken(authUuid: string): Promise<void> {
      return client.post(`/client/v1/auth`, {
        authUserUuid: authUuid,
      })
    },
  }
}

export const authClient: IAuthClient = authClientFactory(httpClient)
