import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { IconCloseSmall } from '@pijma/icon-gallery/crypto'
import { IconLongSmallArrowRight } from '@pijma/icon-gallery/crypto'

import { useToken } from '@hooks'
import styled from 'styled-components'

import { Color } from '../../../../../generated/quicktype/Tokens'
import { EShop } from '../../../../stores/types/EShop'
import { H3Weight600, H4 } from '../../../common/header/Headings'
import { FlexBoxCenteredLandingLayout } from '../../../common/layout/flex-box/FlexBoxCenteredLandingLayout'
import { BodyText500 } from '../../../common/text/BodyText'
import iconHamburger from './assets/iconHamburger.svg'
import picApp from './assets/picApp.svg'
import picWebdesk from './assets/picWebdesk.svg'
import picWebmob from './assets/picWebmob.svg'

const STEAM_LINK = 'https://store.steampowered.com/'

const Header = styled.div<Color>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${(color) => color.bg.page.value};
  padding: 0 4px;
`

const IslandBlock = styled.div`
  display: flex;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 22px;
  font-weight: 600;
  justify-content: space-between;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  max-width: 420px;
  white-space: pre-wrap;
`

const CloseIconWrapper = styled.div<{ shadow: string }>`
  cursor: pointer;
  height: 32px;
  width: 32px;
  position: fixed;
  top: 16px;
  right: 16px;
  align-content: center;
  text-align: center;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: ${(styleProps) => styleProps.shadow};
`

const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  gap: 16px;
  margin-bottom: 24px;

  a {
    color: #000000;

    :hover {
      color: #000000;
    }
  }
`

const ContentHeader = styled.div`
  padding: 0 4px;
`

const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`

const IconHamburgerWrapper = styled.div`
  margin-left: 5px;
  height: 24px;
`

const ImageWrapper = styled.div`
  margin-bottom: 44px;
`

const IconArrow: FC = () => (
  <IconLongSmallArrowRight
    height={24}
    width={24}
    color={useToken().color.icon.light_bg.secondary.default.value}
  />
)

export const HelpLoginPage = () => {
  const redirectTo = useNavigate()
  const crossShadow = useToken().shadow.z2.value

  return (
    <FlexBoxCenteredLandingLayout>
      <Container>
        <Header {...useToken().color}>
          <IslandBlock>
            <H3Weight600>Где найти логин Steam</H3Weight600>
            <CloseIconWrapper
              shadow={`${crossShadow.x} ${crossShadow.y} ${crossShadow.blur} ${crossShadow.spread} ${crossShadow.color}`}
            >
              <IconCloseSmall
                onClick={() => redirectTo(`../${EShop.STEAM}`)}
                height={24}
                width={24}
              />
            </CloseIconWrapper>
          </IslandBlock>
        </Header>
        <ContentBlock>
          <ContentHeader>
            <H4>Через сайт Steam на смартфоне</H4>
          </ContentHeader>
          <ContentRow>
            <IconArrow />
            <BodyText500>
              {'Зайдите '}
              <a href={STEAM_LINK}>{'в\u00A0стим'}</a>
            </BodyText500>
          </ContentRow>
          <ContentRow>
            <IconArrow />
            <BodyText500>Коснитесь иконки</BodyText500>
            <IconHamburgerWrapper>
              <img
                width={16}
                height={24}
                alt={'hamburger'}
                src={iconHamburger}
              />
            </IconHamburgerWrapper>
          </ContentRow>
          <ContentRow>
            <IconArrow />
            <BodyText500>
              {'В меню вы увидите название\u00A0логина'}
            </BodyText500>
          </ContentRow>
        </ContentBlock>
        <ImageWrapper>
          <img alt={'webmob'} src={picWebmob} />
        </ImageWrapper>
        <ContentBlock>
          <ContentHeader>
            <H4>{'Через сайт Steam на\u00A0ПК'}</H4>
          </ContentHeader>
          <ContentRow>
            <IconArrow />
            <BodyText500>
              {'Зайдите '}
              <a href={STEAM_LINK}>в стим</a>
            </BodyText500>
          </ContentRow>
          <ContentRow>
            <IconArrow />
            <BodyText500>
              {'В\u00A0правом верхнем углу кликните\n' +
                'на\u00A0имя пользователя\u00A0> Об\u00A0аккаунте.\n' +
                'Там вы найдёте\u00A0логин.'}
            </BodyText500>
          </ContentRow>
        </ContentBlock>
        <ImageWrapper>
          <img alt={'webdesk'} src={picWebdesk} />
        </ImageWrapper>
        <ContentBlock>
          <ContentHeader>
            <H4>Через приложение Steam</H4>
          </ContentHeader>
          <ContentRow>
            <IconArrow />
            <BodyText500>Зайдите в приложение</BodyText500>
          </ContentRow>
          <ContentRow>
            <IconArrow />
            <BodyText500>
              {'Коснитесь иконки профиля в правом\nверхнем углу'}
            </BodyText500>
          </ContentRow>
          <ContentRow>
            <IconArrow />
            <BodyText500>Там вы увидите свой логин</BodyText500>
          </ContentRow>
        </ContentBlock>
        <ImageWrapper>
          <img alt={'app'} src={picApp} />
        </ImageWrapper>
      </Container>
    </FlexBoxCenteredLandingLayout>
  )
}
