import styled from 'styled-components'

import { H1Value } from '../../../../generated/quicktype/Tokens'

export const TextSpan = styled.span<H1Value>`
  font-family: ${(styleProps) => styleProps.fontFamily};
  font-weight: ${(styleProps) => styleProps.fontWeight};
  font-size: ${(styleProps) => styleProps.fontSize};
  line-height: ${(styleProps) => styleProps.lineHeight};
`
