import { createContext, FC, ReactElement } from 'react'

import { rawTokens } from '@pijma/theme-crypto'

import { Tokens } from '../../../generated/quicktype/Tokens'

export const TokenContext = createContext({} as Tokens)

export interface TokenProviderProps {
  children: ReactElement
}

export const TokenProvider: FC<TokenProviderProps> = (props) => {
  return (
    <TokenContext.Provider value={rawTokens as Tokens}>
      {props.children}
    </TokenContext.Provider>
  )
}
